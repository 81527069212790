/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * consumer types that will be consenting to the various scopes (data clusters)
 * @export
 */
export const ConsumerType = {
    All: 'ALL',
    Individual: 'INDIVIDUAL',
    Organisation: 'ORGANISATION',
    Any: 'ANY'
} as const;
export type ConsumerType = typeof ConsumerType[keyof typeof ConsumerType];


export function ConsumerTypeFromJSON(json: any): ConsumerType {
    return ConsumerTypeFromJSONTyped(json, false);
}

export function ConsumerTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsumerType {
    return json as ConsumerType;
}

export function ConsumerTypeToJSON(value?: ConsumerType | null): any {
    return value as any;
}

