import { callApi, triggerApi } from '@adatree/react-api-sdk';
import {
  Configuration,
  CreateUseCaseRequest,
  DeleteUseCaseRequest,
  GetUseCasesRequest,
  UseCaseApi,
  UseCaseResponse as ApiUseCaseResponse,
  ResponseError,
  UpdateUseCaseRequest,
  ConfigureUseCaseRequest,
  GetUseCaseRequest,
} from '../../../../generated/mgmt/consent';
import { useApiConfigParams } from '../api-config-params.hook';

type UseCaseResponse = {
  data: ApiUseCaseResponse;
  isLoading: boolean;
  error: ResponseError;
  mutate: any;
};
type UseCasesResponse = {
  data: ApiUseCaseResponse[];
  isLoading: boolean;
  error: ResponseError;
  mutate: any;
};

export const useMgmtGetUseCases = () => {
  const apiHookName = 'useMgmtGetUseCases';
  const apiMethodName = 'getUseCases';

  const { configParams } = useApiConfigParams();
  const api = new UseCaseApi(new Configuration(configParams));

  const { data, error, isLoading, mutate } = callApi(api, apiHookName, apiMethodName) as UseCasesResponse;

  return {
    rawResponse: data,
    useCases: data,
    isLoading,
    error,
    mutate,
  };
};

type UseCasesTriggerResponse = {
  getUseCases: (requestParams: GetUseCasesRequest) => Promise<Array<ApiUseCaseResponse>>;
  isMutating: boolean;
  error: ResponseError;
};

export const useMgmtGetUseCasesTrigger = (): UseCasesTriggerResponse => {
  const apiHookName = 'useMgmtGetUseCasesTrigger';
  const apiMethodName = 'getUseCases';

  const { configParams } = useApiConfigParams();
  const api = new UseCaseApi(new Configuration(configParams));

  const { trigger, isMutating, error } = triggerApi(api, apiHookName, apiMethodName);

  return {
    getUseCases: trigger,
    isMutating,
    error,
  };
};

export const useMgmtGetUseCase = (id: string) => {
  const apiHookName = 'useMgmtGetUseCase';
  const apiMethodName = 'getUseCase';
  const requestParameters = { id: id };

  const { configParams } = useApiConfigParams();
  const api = new UseCaseApi(new Configuration(configParams));

  const { data, error, isLoading, mutate } = callApi(
    api,
    apiHookName,
    apiMethodName,
    requestParameters,
  ) as UseCaseResponse;

  return {
    rawResponse: data,
    useCase: data,
    isLoading,
    error,
    mutate,
  };
};

type GetUseCaseTriggerResponse = {
  getUseCase: (requestParams: GetUseCaseRequest) => Promise<ApiUseCaseResponse>;
  isMutating: boolean;
  error: ResponseError;
};

export const useMgmtGetUseCaseTrigger = (): GetUseCaseTriggerResponse => {
  const apiHookName = 'useMgmtGetUseCaseTrigger';
  const apiMethodName = 'getUseCase';

  const { configParams } = useApiConfigParams();
  const api = new UseCaseApi(new Configuration(configParams));

  const { trigger, isMutating, error } = triggerApi(api, apiHookName, apiMethodName);

  return {
    getUseCase: trigger,
    isMutating,
    error,
  };
};

type UseCaseTriggerResponse = {
  createUseCase: (requestParams: CreateUseCaseRequest) => Promise<ApiUseCaseResponse>;
  response: ApiUseCaseResponse;
  isMutating: boolean;
  error: ResponseError;
};

export const useMgmtCreateUseCase = (): UseCaseTriggerResponse => {
  const apiHookName = 'useMgmtCreateUseCase';
  const apiMethodName = 'createUseCase';

  const { configParams } = useApiConfigParams();
  const api = new UseCaseApi(new Configuration(configParams));

  const { trigger, data, isMutating, error } = triggerApi(api, apiHookName, apiMethodName);

  return {
    createUseCase: trigger,
    response: data,
    isMutating,
    error,
  };
};

type UpdateUseCaseTriggerResponse = {
  updateUseCase: (requestParams: UpdateUseCaseRequest) => Promise<void>;
  isMutating: boolean;
  error: ResponseError;
};

export const useMgmtUpdateUseCase = (): UpdateUseCaseTriggerResponse => {
  const apiHookName = 'useMgmtUpdateUseCase';
  const apiMethodName = 'updateUseCase';

  const { configParams } = useApiConfigParams();
  const api = new UseCaseApi(new Configuration(configParams));

  const { trigger, isMutating, error } = triggerApi(api, apiHookName, apiMethodName);

  return {
    updateUseCase: trigger,
    isMutating,
    error,
  };
};

type UpdateUseCaseDataHoldersTriggerResponse = {
  updateUseCaseDataholders: (requestParams: ConfigureUseCaseRequest) => Promise<void>;
  isMutating: boolean;
  error: ResponseError;
};

export const useMgmtUpdateUseCaseDataHolders = (): UpdateUseCaseDataHoldersTriggerResponse => {
  const apiHookName = 'useMgmtUpdateUseCaseDataHolders';
  const apiMethodName = 'configureUseCase';

  const { configParams } = useApiConfigParams();
  const api = new UseCaseApi(new Configuration(configParams));

  const { trigger, isMutating, error } = triggerApi(api, apiHookName, apiMethodName);

  return {
    updateUseCaseDataholders: trigger,
    isMutating,
    error,
  };
};

type DeleteUseCaseTriggerResponse = {
  deleteUseCase: (requestParams: DeleteUseCaseRequest) => Promise<void>;
  isMutating: boolean;
  error: ResponseError;
};

export const useMgmtDeleteUseCase = (): DeleteUseCaseTriggerResponse => {
  const apiHookName = 'useMgmtDeleteUseCase';
  const apiMethodName = 'deleteUseCase';

  const { configParams } = useApiConfigParams();
  const api = new UseCaseApi(new Configuration(configParams));

  const { trigger, isMutating, error } = triggerApi(api, apiHookName, apiMethodName);

  return {
    deleteUseCase: trigger,
    isMutating,
    error,
  };
};
