/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GranteeResponse } from './GranteeResponse';
import {
    GranteeResponseFromJSON,
    GranteeResponseFromJSONTyped,
    GranteeResponseToJSON,
} from './GranteeResponse';
import type { PostUsageAction } from './PostUsageAction';
import {
    PostUsageActionFromJSON,
    PostUsageActionFromJSONTyped,
    PostUsageActionToJSON,
} from './PostUsageAction';

/**
 * 
 * @export
 * @interface ConsentEvent
 */
export interface ConsentEvent {
    /**
     * a UUID of the event that could be used for idempotent event processing
     * @type {string}
     * @memberof ConsentEvent
     */
    eventId?: string;
    /**
     * This identifies the different types of events through a consent's lifecycle. <br/>
     * A consent becomes GRANTED after consumer consent with Data Holder<br/>
     * it can be revoked by a consumer via ADR or Data Holder<br/>
     * an ongoing consent will expire on (if it is not revoked prior to) sharing end date
     * a once_off consent will expire within 24 hours after the consent is GRANTED
     * @type {string}
     * @memberof ConsentEvent
     */
    eventType: ConsentEventEventTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ConsentEvent
     */
    eventDetail?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentEvent
     */
    timestamp: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentEvent
     */
    cdrArrangementId: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentEvent
     */
    consentId: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentEvent
     */
    consumerId: string;
    /**
     * 
     * @type {PostUsageAction}
     * @memberof ConsentEvent
     */
    postUsageAction: PostUsageAction;
    /**
     * 
     * @type {string}
     * @memberof ConsentEvent
     */
    externalId?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentEvent
     */
    sharingEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentEvent
     */
    consumerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentEvent
     */
    consumerMobileNumber?: string;
    /**
     * 
     * @type {GranteeResponse}
     * @memberof ConsentEvent
     */
    grantee?: GranteeResponse;
    /**
     * 
     * @type {object}
     * @memberof ConsentEvent
     */
    customData?: object;
}


/**
 * @export
 */
export const ConsentEventEventTypeEnum = {
    Granted: 'GRANTED',
    Extended: 'EXTENDED',
    Revoked: 'REVOKED',
    Expired: 'EXPIRED',
    DataCollectionCompleted: 'DATA_COLLECTION_COMPLETED',
    WebhookSent: 'WEBHOOK_SENT',
    WebhookSendingFailed: 'WEBHOOK_SENDING_FAILED',
    WebhookSendingFailedClientError: 'WEBHOOK_SENDING_FAILED_CLIENT_ERROR'
} as const;
export type ConsentEventEventTypeEnum = typeof ConsentEventEventTypeEnum[keyof typeof ConsentEventEventTypeEnum];


/**
 * Check if a given object implements the ConsentEvent interface.
 */
export function instanceOfConsentEvent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "eventType" in value;
    isInstance = isInstance && "timestamp" in value;
    isInstance = isInstance && "cdrArrangementId" in value;
    isInstance = isInstance && "consentId" in value;
    isInstance = isInstance && "consumerId" in value;
    isInstance = isInstance && "postUsageAction" in value;

    return isInstance;
}

export function ConsentEventFromJSON(json: any): ConsentEvent {
    return ConsentEventFromJSONTyped(json, false);
}

export function ConsentEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsentEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'eventType': json['eventType'],
        'eventDetail': !exists(json, 'eventDetail') ? undefined : json['eventDetail'],
        'timestamp': json['timestamp'],
        'cdrArrangementId': json['cdrArrangementId'],
        'consentId': json['consentId'],
        'consumerId': json['consumerId'],
        'postUsageAction': PostUsageActionFromJSON(json['postUsageAction']),
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'sharingEndDate': !exists(json, 'sharingEndDate') ? undefined : json['sharingEndDate'],
        'consumerEmail': !exists(json, 'consumerEmail') ? undefined : json['consumerEmail'],
        'consumerMobileNumber': !exists(json, 'consumerMobileNumber') ? undefined : json['consumerMobileNumber'],
        'grantee': !exists(json, 'grantee') ? undefined : GranteeResponseFromJSON(json['grantee']),
        'customData': !exists(json, 'customData') ? undefined : json['customData'],
    };
}

export function ConsentEventToJSON(value?: ConsentEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eventId': value.eventId,
        'eventType': value.eventType,
        'eventDetail': value.eventDetail,
        'timestamp': value.timestamp,
        'cdrArrangementId': value.cdrArrangementId,
        'consentId': value.consentId,
        'consumerId': value.consumerId,
        'postUsageAction': PostUsageActionToJSON(value.postUsageAction),
        'externalId': value.externalId,
        'sharingEndDate': value.sharingEndDate,
        'consumerEmail': value.consumerEmail,
        'consumerMobileNumber': value.consumerMobileNumber,
        'grantee': GranteeResponseToJSON(value.grantee),
        'customData': value.customData,
    };
}

