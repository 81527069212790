/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LinksPaginated
 */
export interface LinksPaginated {
    /**
     * Fully qualified link that generated the current response document. https://self.example.com.au will be converted to https://self.example.com.au?page=1&page-size=25
     * @type {string}
     * @memberof LinksPaginated
     */
    self: string;
    /**
     * URI to the first page of this set. Mandatory if this response is not the first page
     * @type {string}
     * @memberof LinksPaginated
     */
    first?: string;
    /**
     * URI to the previous page of this set. Mandatory if this response is not the first page
     * @type {string}
     * @memberof LinksPaginated
     */
    prev?: string;
    /**
     * URI to the next page of this set. Mandatory if this response is not the last page
     * @type {string}
     * @memberof LinksPaginated
     */
    next?: string;
    /**
     * URI to the last page of this set. Mandatory if this response is not the last page
     * @type {string}
     * @memberof LinksPaginated
     */
    last?: string;
}

/**
 * Check if a given object implements the LinksPaginated interface.
 */
export function instanceOfLinksPaginated(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "self" in value;

    return isInstance;
}

export function LinksPaginatedFromJSON(json: any): LinksPaginated {
    return LinksPaginatedFromJSONTyped(json, false);
}

export function LinksPaginatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinksPaginated {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'self': json['self'],
        'first': !exists(json, 'first') ? undefined : json['first'],
        'prev': !exists(json, 'prev') ? undefined : json['prev'],
        'next': !exists(json, 'next') ? undefined : json['next'],
        'last': !exists(json, 'last') ? undefined : json['last'],
    };
}

export function LinksPaginatedToJSON(value?: LinksPaginated | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'self': value.self,
        'first': value.first,
        'prev': value.prev,
        'next': value.next,
        'last': value.last,
    };
}

