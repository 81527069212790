/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ResponseErrorList,
  ScopeResponse,
  UpdateUseCase,
  UseCase,
  UseCaseResponse,
} from '../models';
import {
    ResponseErrorListFromJSON,
    ResponseErrorListToJSON,
    ScopeResponseFromJSON,
    ScopeResponseToJSON,
    UpdateUseCaseFromJSON,
    UpdateUseCaseToJSON,
    UseCaseFromJSON,
    UseCaseToJSON,
    UseCaseResponseFromJSON,
    UseCaseResponseToJSON,
} from '../models';

export interface ConfigureUseCaseRequest {
    id: string;
    updateUseCase?: UpdateUseCase;
}

export interface CreateUseCaseRequest {
    useCase?: UseCase;
}

export interface DeleteUseCaseRequest {
    id: string;
}

export interface FindScopesRequest {
    name?: string;
}

export interface GetUseCaseRequest {
    id: string;
}

export interface GetUseCasesRequest {
    active?: boolean;
}

export interface UpdateUseCaseRequest {
    id: string;
    useCase?: UseCase;
}

/**
 * 
 */
export class UseCaseApi extends runtime.BaseAPI {

    /**
     * Configure allowed data holders
     * Configure Use-case
     */
    async configureUseCaseRaw(requestParameters: ConfigureUseCaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UseCaseResponse>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling configureUseCase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("m2m", []);
        }

        const response = await this.request({
            path: `/use-cases/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUseCaseToJSON(requestParameters.updateUseCase),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UseCaseResponseFromJSON));
    }

    /**
     * Configure allowed data holders
     * Configure Use-case
     */
    async configureUseCase(requestParameters: ConfigureUseCaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UseCaseResponse>> {
        const response = await this.configureUseCaseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a use-cases. A use case is your reason for requesting consent from a consumer e.g. a home loan assessment, a budgeting app.
     * Create Use-case
     */
    async createUseCaseRaw(requestParameters: CreateUseCaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UseCaseResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("m2m", []);
        }

        const response = await this.request({
            path: `/use-cases`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UseCaseToJSON(requestParameters.useCase),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UseCaseResponseFromJSON(jsonValue));
    }

    /**
     * Create a use-cases. A use case is your reason for requesting consent from a consumer e.g. a home loan assessment, a budgeting app.
     * Create Use-case
     */
    async createUseCase(requestParameters: CreateUseCaseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UseCaseResponse> {
        const response = await this.createUseCaseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a use-case that has been configured.
     * Delete Use-case
     */
    async deleteUseCaseRaw(requestParameters: DeleteUseCaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUseCase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("m2m", []);
        }

        const response = await this.request({
            path: `/use-cases/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a use-case that has been configured.
     * Delete Use-case
     */
    async deleteUseCase(requestParameters: DeleteUseCaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUseCaseRaw(requestParameters, initOverrides);
    }

    /**
     * Find all Scopes that have been configured. A Scope is an authorization request as defined by the CDR standards (https://consumerdatastandardsaustralia.github.io/standards/#authorisation-scopes). A Scope object contains a human readable version of claims to be displayed in your consumer facing application. Pre-configured platform scopes are Basic Customer Data, Basic Bank Account Data, Detailed Bank Account Data, Bank Transaction Data. These authorization scopes are for the first four APIs exposed by the CDR regime. New scopes can be added to form part of your use case via this API. No code updates are required. The Adatree platform will come preconfigured with all available CDR scopes.
     * Find Scopes
     */
    async findScopesRaw(requestParameters: FindScopesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ScopeResponse>>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("m2m", []);
        }

        const response = await this.request({
            path: `/management/scopes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ScopeResponseFromJSON));
    }

    /**
     * Find all Scopes that have been configured. A Scope is an authorization request as defined by the CDR standards (https://consumerdatastandardsaustralia.github.io/standards/#authorisation-scopes). A Scope object contains a human readable version of claims to be displayed in your consumer facing application. Pre-configured platform scopes are Basic Customer Data, Basic Bank Account Data, Detailed Bank Account Data, Bank Transaction Data. These authorization scopes are for the first four APIs exposed by the CDR regime. New scopes can be added to form part of your use case via this API. No code updates are required. The Adatree platform will come preconfigured with all available CDR scopes.
     * Find Scopes
     */
    async findScopes(requestParameters: FindScopesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ScopeResponse>> {
        const response = await this.findScopesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a use-case that has been configured. A use case is your reason for requesting consent from a consumer e.g. a home loan assessment, a budgeting app.
     * Get Use-case
     */
    async getUseCaseRaw(requestParameters: GetUseCaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UseCaseResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUseCase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("m2m", ["use-cases:read"]);
        }

        const response = await this.request({
            path: `/use-cases/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UseCaseResponseFromJSON(jsonValue));
    }

    /**
     * Get a use-case that has been configured. A use case is your reason for requesting consent from a consumer e.g. a home loan assessment, a budgeting app.
     * Get Use-case
     */
    async getUseCase(requestParameters: GetUseCaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UseCaseResponse> {
        const response = await this.getUseCaseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all use-cases that has been configured. A use case is your reason for requesting consent from a consumer e.g. a home loan assessment, a budgeting app. The Adatree Platform will come pre-configured with your use cases.
     * Get Use-cases
     */
    async getUseCasesRaw(requestParameters: GetUseCasesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UseCaseResponse>>> {
        const queryParameters: any = {};

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("m2m", ["use-cases:read"]);
        }

        const response = await this.request({
            path: `/use-cases`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UseCaseResponseFromJSON));
    }

    /**
     * Get all use-cases that has been configured. A use case is your reason for requesting consent from a consumer e.g. a home loan assessment, a budgeting app. The Adatree Platform will come pre-configured with your use cases.
     * Get Use-cases
     */
    async getUseCases(requestParameters: GetUseCasesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UseCaseResponse>> {
        const response = await this.getUseCasesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a use-case that has been configured.
     * Update Use-case
     */
    async updateUseCaseRaw(requestParameters: UpdateUseCaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUseCase.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("m2m", []);
        }

        const response = await this.request({
            path: `/use-cases/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UseCaseToJSON(requestParameters.useCase),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a use-case that has been configured.
     * Update Use-case
     */
    async updateUseCase(requestParameters: UpdateUseCaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateUseCaseRaw(requestParameters, initOverrides);
    }

}
