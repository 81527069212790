/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UsageDetail } from './UsageDetail';
import {
    UsageDetailFromJSON,
    UsageDetailFromJSONTyped,
    UsageDetailToJSON,
} from './UsageDetail';
import type { UsageSummary } from './UsageSummary';
import {
    UsageSummaryFromJSON,
    UsageSummaryFromJSONTyped,
    UsageSummaryToJSON,
} from './UsageSummary';

/**
 * Usage response
 * @export
 * @interface UsageResponse
 */
export interface UsageResponse {
    /**
     * 
     * @type {UsageSummary}
     * @memberof UsageResponse
     */
    summary: UsageSummary;
    /**
     * array of values representing the aggregation result
     * @type {Array<UsageDetail>}
     * @memberof UsageResponse
     */
    details: Array<UsageDetail>;
}

/**
 * Check if a given object implements the UsageResponse interface.
 */
export function instanceOfUsageResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "summary" in value;
    isInstance = isInstance && "details" in value;

    return isInstance;
}

export function UsageResponseFromJSON(json: any): UsageResponse {
    return UsageResponseFromJSONTyped(json, false);
}

export function UsageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsageResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'summary': UsageSummaryFromJSON(json['summary']),
        'details': ((json['details'] as Array<any>).map(UsageDetailFromJSON)),
    };
}

export function UsageResponseToJSON(value?: UsageResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'summary': UsageSummaryToJSON(value.summary),
        'details': ((value.details as Array<any>).map(UsageDetailToJSON)),
    };
}

