/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Scope = {
    BankaccountsBasicread: 'bank:accounts.basic:read',
    BankaccountsDetailread: 'bank:accounts.detail:read',
    Banktransactionsread: 'bank:transactions:read',
    Bankpayeesread: 'bank:payees:read',
    BankregularPaymentsread: 'bank:regular_payments:read',
    EnergyaccountsBasicread: 'energy:accounts.basic:read',
    EnergyaccountsDetailread: 'energy:accounts.detail:read',
    EnergyaccountsConcessionsread: 'energy:accounts.concessions:read',
    EnergyaccountsPaymentscheduleread: 'energy:accounts.paymentschedule:read',
    Energybillingread: 'energy:billing:read',
    EnergyelectricityServicepointsBasicread: 'energy:electricity.servicepoints.basic:read',
    EnergyelectricityServicepointsDetailread: 'energy:electricity.servicepoints.detail:read',
    EnergyelectricityDerread: 'energy:electricity.der:read',
    EnergyelectricityUsageread: 'energy:electricity.usage:read',
    CommoncustomerBasicread: 'common:customer.basic:read',
    CommoncustomerDetailread: 'common:customer.detail:read',
    Profile: 'profile',
    EmailPhoneAddress: 'email phone address',
    CdrinsightssvaccountVerification: 'insights:sv:banking:account-verification'
} as const;
export type Scope = typeof Scope[keyof typeof Scope];


export function ScopeFromJSON(json: any): Scope {
    return ScopeFromJSONTyped(json, false);
}

export function ScopeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Scope {
    return json as Scope;
}

export function ScopeToJSON(value?: Scope | null): any {
    return value as any;
}

