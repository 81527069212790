/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Usage detail response
 * @export
 * @interface UsageDetail
 */
export interface UsageDetail {
    /**
     * date of the bucket zeroed out to the start of the period
     * @type {string}
     * @memberof UsageDetail
     */
    date: string;
    /**
     * number of results for the period
     * @type {number}
     * @memberof UsageDetail
     */
    count: number;
    /**
     * particular attribute value grouped by.
     * @type {string}
     * @memberof UsageDetail
     */
    attribute?: string;
}

/**
 * Check if a given object implements the UsageDetail interface.
 */
export function instanceOfUsageDetail(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "count" in value;

    return isInstance;
}

export function UsageDetailFromJSON(json: any): UsageDetail {
    return UsageDetailFromJSONTyped(json, false);
}

export function UsageDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsageDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': json['date'],
        'count': json['count'],
        'attribute': !exists(json, 'attribute') ? undefined : json['attribute'],
    };
}

export function UsageDetailToJSON(value?: UsageDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'count': value.count,
        'attribute': value.attribute,
    };
}

