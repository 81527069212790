/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Usage summary response
 * @export
 * @interface UsageSummary
 */
export interface UsageSummary {
    /**
     * The usage type of the response
     * @type {string}
     * @memberof UsageSummary
     */
    type: string;
    /**
     * The dimension requested
     * @type {string}
     * @memberof UsageSummary
     */
    dimension: string;
    /**
     * the earliest date for the whole period
     * @type {string}
     * @memberof UsageSummary
     */
    start: string;
    /**
     * the latest date for the whole period
     * @type {string}
     * @memberof UsageSummary
     */
    end: string;
    /**
     * the total number of results for the whole period
     * @type {number}
     * @memberof UsageSummary
     */
    total: number;
}

/**
 * Check if a given object implements the UsageSummary interface.
 */
export function instanceOfUsageSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "dimension" in value;
    isInstance = isInstance && "start" in value;
    isInstance = isInstance && "end" in value;
    isInstance = isInstance && "total" in value;

    return isInstance;
}

export function UsageSummaryFromJSON(json: any): UsageSummary {
    return UsageSummaryFromJSONTyped(json, false);
}

export function UsageSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsageSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'dimension': json['dimension'],
        'start': json['start'],
        'end': json['end'],
        'total': json['total'],
    };
}

export function UsageSummaryToJSON(value?: UsageSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'dimension': value.dimension,
        'start': value.start,
        'end': value.end,
        'total': value.total,
    };
}

