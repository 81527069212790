/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GranteeListResponse,
  GranteeRequest,
  GranteeResponse,
  ResponseErrorList,
} from '../models';
import {
    GranteeListResponseFromJSON,
    GranteeListResponseToJSON,
    GranteeRequestFromJSON,
    GranteeRequestToJSON,
    GranteeResponseFromJSON,
    GranteeResponseToJSON,
    ResponseErrorListFromJSON,
    ResponseErrorListToJSON,
} from '../models';

export interface CreateGranteesRequest {
    granteeRequest?: GranteeRequest;
}

export interface GranteesIdDeleteRequest {
    id: string;
}

export interface GranteesIdGetRequest {
    id: string;
}

export interface GranteesIdPutRequest {
    id: string;
}

export interface ListGranteesRequest {
    page?: number;
    pageSize?: number;
}

/**
 * 
 */
export class GranteeApi extends runtime.BaseAPI {

    /**
     * Create new grantee, which could be a trusted advisor
     */
    async createGranteesRaw(requestParameters: CreateGranteesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GranteeResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/grantees`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GranteeRequestToJSON(requestParameters.granteeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GranteeResponseFromJSON(jsonValue));
    }

    /**
     * Create new grantee, which could be a trusted advisor
     */
    async createGrantees(requestParameters: CreateGranteesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GranteeResponse> {
        const response = await this.createGranteesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async granteesIdDeleteRaw(requestParameters: GranteesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling granteesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/grantees/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async granteesIdDelete(requestParameters: GranteesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.granteesIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieve a grantee
     */
    async granteesIdGetRaw(requestParameters: GranteesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GranteeResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling granteesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/grantees/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GranteeResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve a grantee
     */
    async granteesIdGet(requestParameters: GranteesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GranteeResponse> {
        const response = await this.granteesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async granteesIdPutRaw(requestParameters: GranteesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GranteeRequest>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling granteesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/grantees/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GranteeRequestFromJSON(jsonValue));
    }

    /**
     */
    async granteesIdPut(requestParameters: GranteesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GranteeRequest> {
        const response = await this.granteesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List grantees
     */
    async listGranteesRaw(requestParameters: ListGranteesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GranteeListResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page-size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/grantees`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GranteeListResponseFromJSON(jsonValue));
    }

    /**
     * List grantees
     */
    async listGrantees(requestParameters: ListGranteesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GranteeListResponse> {
        const response = await this.listGranteesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
