import * as React from 'react';
import { Box } from '@mui/material';
import { ReactElement } from 'react';
import { SideMenu } from '../../molecules/side-menu/side-menu.molecule';
import { LayoutSideDrawer } from './layout-side-drawer.organism';
import { useHelp } from '../../../app-logic/context/help.context';
import { HelpIcon } from '../../atoms/icons/icons.atom';

interface Props {
  children: ReactElement;
}

export const LayoutBody = ({ children }: Props) => {
  const { helpContent, open, setOpen } = useHelp();

  return (
    <Box sx={{ display: 'flex' }}>
      <LayoutSideDrawer open={true}>
        <>
          <SideMenu />

          <Box sx={{ position: 'absolute', bottom: '40px', left: '30px', display: 'flex', alignItems: 'center' }}>
            <a href="https://adatree.atlassian.net/servicedesk/customer/portals" target="_blank" rel="noreferrer">
              <span style={{ display: 'flex' }}>
                <HelpIcon size={22} style={{ marginRight: '4px' }} /> Raise a support ticket
              </span>
            </a>
          </Box>
        </>
      </LayoutSideDrawer>
      <Box id="adt-main" component="main" sx={{ flexGrow: 1, p: 3, pb: 10, mt: '6.4rem' }}>
        {children}
      </Box>
      {helpContent && (
        <LayoutSideDrawer
          anchorSide="right"
          lazyload={true}
          width={600}
          gutter
          open={open}
          onClose={(isOpen) => {
            setOpen(isOpen);
          }}
        >
          <>{helpContent}</>
        </LayoutSideDrawer>
      )}
    </Box>
  );
};
