import * as React from 'react';
import { CircularProgress } from '@mui/material';
import { CenterLayout } from '../center-layout/center-layout.atom';

export const LoadingSpinner = () => {
  return (
    <CenterLayout>
      <CircularProgress />
    </CenterLayout>
  );
};
