/* tslint:disable */
/* eslint-disable */
/**
 * Adatree REST API for managing Software Products
 * DCR Management APIs allow ADR customers to * enable / disable software products after it is LIVE in ACCC registry and synced back into Adatree ADR platform * provides a way to soft launch software products or take software products offline 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DataHolder,
} from '../models';
import {
    DataHolderFromJSON,
    DataHolderToJSON,
} from '../models';

/**
 * 
 */
export class DataHoldersApi extends runtime.BaseAPI {

    /**
     */
    async getRegisteredDataHoldersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DataHolder>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("m2m", []);
        }

        const response = await this.request({
            path: `/management/data-holders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DataHolderFromJSON));
    }

    /**
     */
    async getRegisteredDataHolders(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DataHolder>> {
        const response = await this.getRegisteredDataHoldersRaw(initOverrides);
        return await response.value();
    }

}
