/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Grantee } from './Grantee';
import {
    GranteeFromJSON,
    GranteeFromJSONTyped,
    GranteeToJSON,
} from './Grantee';
import type { PostUsageAction } from './PostUsageAction';
import {
    PostUsageActionFromJSON,
    PostUsageActionFromJSONTyped,
    PostUsageActionToJSON,
} from './PostUsageAction';

/**
 * 
 * @export
 * @interface CreateConsent
 */
export interface CreateConsent {
    /**
     * this is required if chosen Use Case uses email as notification channel
     * @type {string}
     * @memberof CreateConsent
     */
    consumerEmail?: string;
    /**
     * sharing end date, will be validated against sharing duration options defined in chosen Use Case
     * @type {string}
     * @memberof CreateConsent
     */
    sharingEndDate: string;
    /**
     * 
     * @type {string}
     * @memberof CreateConsent
     */
    dataHolderBrandId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateConsent
     */
    useCaseId: string;
    /**
     * 
     * @type {PostUsageAction}
     * @memberof CreateConsent
     */
    postUsageAction?: PostUsageAction;
    /**
     * 
     * @type {boolean}
     * @memberof CreateConsent
     */
    directMarketingAllowed?: boolean;
    /**
     * this is required if chosen Use Case uses mobile number as notification channel
     * @type {string}
     * @memberof CreateConsent
     */
    consumerMobileNumber?: string;
    /**
     * Adatree's customer can supply an externalId when creating consents to associate with records in its own system
     * @type {string}
     * @memberof CreateConsent
     */
    externalId?: string;
    /**
     * consumerId, please be kindly reminded of proper encoding as Id from some IDP could have special character like '|', which need be encoded as '%7c'. <br/>
     * consumerId is required (and only accepted) for back-channel consent creation
     * @type {string}
     * @memberof CreateConsent
     */
    consumerId?: string;
    /**
     * 
     * @type {Grantee}
     * @memberof CreateConsent
     */
    grantee?: Grantee;
    /**
     * 
     * @type {object}
     * @memberof CreateConsent
     */
    metadata?: object;
    /**
     * deprecated. use metadata
     * @type {object}
     * @memberof CreateConsent
     */
    customData?: object;
}

/**
 * Check if a given object implements the CreateConsent interface.
 */
export function instanceOfCreateConsent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sharingEndDate" in value;
    isInstance = isInstance && "dataHolderBrandId" in value;
    isInstance = isInstance && "useCaseId" in value;

    return isInstance;
}

export function CreateConsentFromJSON(json: any): CreateConsent {
    return CreateConsentFromJSONTyped(json, false);
}

export function CreateConsentFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateConsent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'consumerEmail': !exists(json, 'consumerEmail') ? undefined : json['consumerEmail'],
        'sharingEndDate': json['sharingEndDate'],
        'dataHolderBrandId': json['dataHolderBrandId'],
        'useCaseId': json['useCaseId'],
        'postUsageAction': !exists(json, 'postUsageAction') ? undefined : PostUsageActionFromJSON(json['postUsageAction']),
        'directMarketingAllowed': !exists(json, 'directMarketingAllowed') ? undefined : json['directMarketingAllowed'],
        'consumerMobileNumber': !exists(json, 'consumerMobileNumber') ? undefined : json['consumerMobileNumber'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'consumerId': !exists(json, 'consumerId') ? undefined : json['consumerId'],
        'grantee': !exists(json, 'grantee') ? undefined : GranteeFromJSON(json['grantee']),
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'customData': !exists(json, 'customData') ? undefined : json['customData'],
    };
}

export function CreateConsentToJSON(value?: CreateConsent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'consumerEmail': value.consumerEmail,
        'sharingEndDate': value.sharingEndDate,
        'dataHolderBrandId': value.dataHolderBrandId,
        'useCaseId': value.useCaseId,
        'postUsageAction': PostUsageActionToJSON(value.postUsageAction),
        'directMarketingAllowed': value.directMarketingAllowed,
        'consumerMobileNumber': value.consumerMobileNumber,
        'externalId': value.externalId,
        'consumerId': value.consumerId,
        'grantee': GranteeToJSON(value.grantee),
        'metadata': value.metadata,
        'customData': value.customData,
    };
}

