import React, { ReactNode } from 'react';
import { UserManager } from 'oidc-client-ts';
import { AuthProvider } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { APP_CONSTANTS } from '../constants/app.const';
import { URL_PAGES } from '../constants/url.const';

interface Props {
  children: ReactNode;
}

const userManager: UserManager = new UserManager({
  authority: process.env.REACT_APP_AUTHORITY ?? '',
  client_id: process.env.REACT_APP_CLIENT_ID ?? '',
  loadUserInfo: process.env.REACT_APP_LOADUSERINFO === 'true' ? true : false,
  extraQueryParams: JSON.parse(`${process.env.REACT_APP_EXTRA_QUERY_PARAMS}`),
  metadata: {
    authorization_endpoint: process.env.REACT_APP_AUTHORIZATION_ENDPOINT,
    end_session_endpoint: process.env.REACT_APP_END_SESSION_ENDPOINT,
    issuer: process.env.REACT_APP_ISSUER,
    jwks_uri: process.env.REACT_APP_JWKS_URI,
    token_endpoint: process.env.REACT_APP_TOKEN_ENDPOINT,
    userinfo_endpoint: process.env.REACT_APP_USERINFO_ENDPOINT,
  },
  redirect_uri: process.env.REACT_APP_REDIRECT_URI ?? '',
  response_type: process.env.REACT_APP_RESPONSE_TYPE,
  scope: process.env.REACT_APP_SCOPE,
});

export const AuthenticationProvider: React.FC<Props> = ({ children }: Props) => {
  const navigate = useNavigate();
  const oidcConfig = {
    userManager: userManager,
    autoSignIn: true,
    onSigninCallback: async () => {
      // Clear the auth code and state parameters from the URL
      window.history.replaceState({}, document.title, window.location.pathname);

      const returnUri = localStorage.getItem(APP_CONSTANTS.storageKeys.returnUri);

      if (returnUri) {
        localStorage.removeItem(APP_CONSTANTS.storageKeys.returnUri);
        navigate(returnUri);
      } else {
        navigate(URL_PAGES.HOME.url);
      }
    },
  };

  userManager.clearStaleState();

  return (
    <>
      <AuthProvider {...oidcConfig}>{children}</AuthProvider>
    </>
  );
};
