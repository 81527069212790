import React from 'react';
import App from './app';
import ReactDOM from 'react-dom/client';
import './app.css';
import { StrictMode } from 'react';
import { AuthenticationProvider } from './app-logic/providers/authentication.provider';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <CssBaseline />
    <BrowserRouter>
      <AuthenticationProvider>
        <App />
      </AuthenticationProvider>
    </BrowserRouter>
  </StrictMode>,
);
