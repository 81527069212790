export const COLOUR = {
  INFO: '#2C93D6',
  SUCCESS: '#51BD95',
  CAUTION: '#ffe920',
  WARNING: '#ff9420',
  DANGER: '#D14343',
  DISABLED: '#f5f5f5',
  GREY_LIGHT: '#cccccc',
  GREY: '#5f5f5f',
  GREY_DARK: '#333333',
  HOVER: '#C4F4F0',
  TERTIARY_LIGHT: '#cb6cf4',
  TERTIARY: '#B62DF0',
  TERTIARY_DARK: '#9124c0',
};
