/* tslint:disable */
/* eslint-disable */
/**
 * Adatree REST API for managing Software Products
 * DCR Management APIs allow ADR customers to * enable / disable software products after it is LIVE in ACCC registry and synced back into Adatree ADR platform * provides a way to soft launch software products or take software products offline 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataHolder } from './DataHolder';
import {
    DataHolderFromJSON,
    DataHolderFromJSONTyped,
    DataHolderToJSON,
} from './DataHolder';
import type { Tenant } from './Tenant';
import {
    TenantFromJSON,
    TenantFromJSONTyped,
    TenantToJSON,
} from './Tenant';

/**
 * 
 * @export
 * @interface TenantStatusDcr
 */
export interface TenantStatusDcr {
    /**
     * 
     * @type {string}
     * @memberof TenantStatusDcr
     */
    rawConfig?: string;
    /**
     * 
     * @type {Array<DataHolder>}
     * @memberof TenantStatusDcr
     */
    dataHolders: Array<DataHolder>;
    /**
     * 
     * @type {Array<Tenant>}
     * @memberof TenantStatusDcr
     */
    tenants: Array<Tenant>;
}

/**
 * Check if a given object implements the TenantStatusDcr interface.
 */
export function instanceOfTenantStatusDcr(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dataHolders" in value;
    isInstance = isInstance && "tenants" in value;

    return isInstance;
}

export function TenantStatusDcrFromJSON(json: any): TenantStatusDcr {
    return TenantStatusDcrFromJSONTyped(json, false);
}

export function TenantStatusDcrFromJSONTyped(json: any, ignoreDiscriminator: boolean): TenantStatusDcr {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rawConfig': !exists(json, 'rawConfig') ? undefined : json['rawConfig'],
        'dataHolders': ((json['dataHolders'] as Array<any>).map(DataHolderFromJSON)),
        'tenants': ((json['tenants'] as Array<any>).map(TenantFromJSON)),
    };
}

export function TenantStatusDcrToJSON(value?: TenantStatusDcr | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rawConfig': value.rawConfig,
        'dataHolders': ((value.dataHolders as Array<any>).map(DataHolderToJSON)),
        'tenants': ((value.tenants as Array<any>).map(TenantToJSON)),
    };
}

