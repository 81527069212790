/* tslint:disable */
/* eslint-disable */
/**
 * Adatree REST API for managing Software Products
 * DCR Management APIs allow ADR customers to * enable / disable software products after it is LIVE in ACCC registry and synced back into Adatree ADR platform * provides a way to soft launch software products or take software products offline 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StaleDcrClient
 */
export interface StaleDcrClient {
    /**
     * 
     * @type {string}
     * @memberof StaleDcrClient
     */
    dataHolderName: string;
    /**
     * 
     * @type {string}
     * @memberof StaleDcrClient
     */
    dcrUpdated?: string;
    /**
     * 
     * @type {string}
     * @memberof StaleDcrClient
     */
    lastDcrSuccess: string;
    /**
     * 
     * @type {string}
     * @memberof StaleDcrClient
     */
    lastDcrAttempt?: string;
    /**
     * 
     * @type {string}
     * @memberof StaleDcrClient
     */
    lastDcrFailure?: string;
    /**
     * 
     * @type {string}
     * @memberof StaleDcrClient
     */
    daysSinceStale: string;
}

/**
 * Check if a given object implements the StaleDcrClient interface.
 */
export function instanceOfStaleDcrClient(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dataHolderName" in value;
    isInstance = isInstance && "lastDcrSuccess" in value;
    isInstance = isInstance && "daysSinceStale" in value;

    return isInstance;
}

export function StaleDcrClientFromJSON(json: any): StaleDcrClient {
    return StaleDcrClientFromJSONTyped(json, false);
}

export function StaleDcrClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): StaleDcrClient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataHolderName': json['dataHolderName'],
        'dcrUpdated': !exists(json, 'dcrUpdated') ? undefined : json['dcrUpdated'],
        'lastDcrSuccess': json['lastDcrSuccess'],
        'lastDcrAttempt': !exists(json, 'lastDcrAttempt') ? undefined : json['lastDcrAttempt'],
        'lastDcrFailure': !exists(json, 'lastDcrFailure') ? undefined : json['lastDcrFailure'],
        'daysSinceStale': json['daysSinceStale'],
    };
}

export function StaleDcrClientToJSON(value?: StaleDcrClient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataHolderName': value.dataHolderName,
        'dcrUpdated': value.dcrUpdated,
        'lastDcrSuccess': value.lastDcrSuccess,
        'lastDcrAttempt': value.lastDcrAttempt,
        'lastDcrFailure': value.lastDcrFailure,
        'daysSinceStale': value.daysSinceStale,
    };
}

