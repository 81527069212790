import React, { ReactElement } from 'react';
import { Box } from '@mui/material';

interface Props {
  children: ReactElement;
  maxWidth?: string;
}

export const PageWrapperFullWidth = ({ children }: Props) => {
  return <Box>{children}</Box>;
};
