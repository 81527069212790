/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PostUsageAction = {
    Deletion: 'DELETION',
    DeIdentification: 'DE_IDENTIFICATION'
} as const;
export type PostUsageAction = typeof PostUsageAction[keyof typeof PostUsageAction];


export function PostUsageActionFromJSON(json: any): PostUsageAction {
    return PostUsageActionFromJSONTyped(json, false);
}

export function PostUsageActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostUsageAction {
    return json as PostUsageAction;
}

export function PostUsageActionToJSON(value?: PostUsageAction | null): any {
    return value as any;
}

