import React, { useState } from 'react';
import { Snackbar } from '@mui/material';
import { ErrorMessage } from '../error-message/error-message.atom';
import { ResponseError } from '@adatree/react-api-sdk/dist/generated/data';

interface Props {
  message: string;
  error: ResponseError;
  onClose?: () => void;
}

export const ErrorSnackbar = ({ message, error, onClose }: Props) => {
  const [isOpen, setIsOpen] = useState(!!error);

  const handleClose = () => {
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <Snackbar open={isOpen} onClose={handleClose} autoHideDuration={10000}>
      <div>
        <ErrorMessage userFriendlyMessage={message} error={error} onClose={handleClose} />
      </div>
    </Snackbar>
  );
};
