/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConsentActivatedResponse
 */
export interface ConsentActivatedResponse {
    /**
     * indicates the outcome of the authorization request
     * @type {boolean}
     * @memberof ConsentActivatedResponse
     */
    success: boolean;
    /**
     * Identifier of the consent which has now been activated by the completion of the Authorization flow.
     * @type {string}
     * @memberof ConsentActivatedResponse
     */
    activeConsentId?: string;
    /**
     * The original OAuth 2.0 state parameter that is provided as part of the authorization process
     * @type {string}
     * @memberof ConsentActivatedResponse
     */
    state?: string;
    /**
     * An Adatree provided message of what went wrong
     * @type {string}
     * @memberof ConsentActivatedResponse
     */
    adatreeErrorMessage?: string;
    /**
     * An indication of the error returned by the data holder
     * @type {string}
     * @memberof ConsentActivatedResponse
     */
    dataHolderErrorMessage?: string;
    /**
     * the type of error identified
     * @type {string}
     * @memberof ConsentActivatedResponse
     */
    errorCode?: string;
    /**
     * The expiry (exp) claim of the provided response JWT.
     * If the provided JWT is valid, this request should be retryable until the JWT expires as this JWT
     * represents an activation of a consent at a Data Holder
     * @type {string}
     * @memberof ConsentActivatedResponse
     */
    retryableUntil?: string;
}

/**
 * Check if a given object implements the ConsentActivatedResponse interface.
 */
export function instanceOfConsentActivatedResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;

    return isInstance;
}

export function ConsentActivatedResponseFromJSON(json: any): ConsentActivatedResponse {
    return ConsentActivatedResponseFromJSONTyped(json, false);
}

export function ConsentActivatedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsentActivatedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'activeConsentId': !exists(json, 'activeConsentId') ? undefined : json['activeConsentId'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'adatreeErrorMessage': !exists(json, 'adatreeErrorMessage') ? undefined : json['adatreeErrorMessage'],
        'dataHolderErrorMessage': !exists(json, 'dataHolderErrorMessage') ? undefined : json['dataHolderErrorMessage'],
        'errorCode': !exists(json, 'errorCode') ? undefined : json['errorCode'],
        'retryableUntil': !exists(json, 'retryableUntil') ? undefined : json['retryableUntil'],
    };
}

export function ConsentActivatedResponseToJSON(value?: ConsentActivatedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'activeConsentId': value.activeConsentId,
        'state': value.state,
        'adatreeErrorMessage': value.adatreeErrorMessage,
        'dataHolderErrorMessage': value.dataHolderErrorMessage,
        'errorCode': value.errorCode,
        'retryableUntil': value.retryableUntil,
    };
}

