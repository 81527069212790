/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AccessFrequency = {
    OnceOff: 'ONCE_OFF',
    Ongoing: 'ONGOING'
} as const;
export type AccessFrequency = typeof AccessFrequency[keyof typeof AccessFrequency];


export function AccessFrequencyFromJSON(json: any): AccessFrequency {
    return AccessFrequencyFromJSONTyped(json, false);
}

export function AccessFrequencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessFrequency {
    return json as AccessFrequency;
}

export function AccessFrequencyToJSON(value?: AccessFrequency | null): any {
    return value as any;
}

