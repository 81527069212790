/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UsageResponse,
} from '../models';
import {
    UsageResponseFromJSON,
    UsageResponseToJSON,
} from '../models';

export interface ManagementUsageGetRequest {
    type: ManagementUsageGetTypeEnum;
    dimension: ManagementUsageGetDimensionEnum;
    relative: ManagementUsageGetRelativeEnum;
    group?: ManagementUsageGetGroupEnum;
    oldest?: string;
    newest?: string;
}

/**
 * 
 */
export class UsageApi extends runtime.BaseAPI {

    /**
     */
    async managementUsageGetRaw(requestParameters: ManagementUsageGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsageResponse>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling managementUsageGet.');
        }

        if (requestParameters.dimension === null || requestParameters.dimension === undefined) {
            throw new runtime.RequiredError('dimension','Required parameter requestParameters.dimension was null or undefined when calling managementUsageGet.');
        }

        if (requestParameters.relative === null || requestParameters.relative === undefined) {
            throw new runtime.RequiredError('relative','Required parameter requestParameters.relative was null or undefined when calling managementUsageGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.dimension !== undefined) {
            queryParameters['dimension'] = requestParameters.dimension;
        }

        if (requestParameters.group !== undefined) {
            queryParameters['group'] = requestParameters.group;
        }

        if (requestParameters.relative !== undefined) {
            queryParameters['relative'] = requestParameters.relative;
        }

        if (requestParameters.oldest !== undefined) {
            queryParameters['oldest'] = requestParameters.oldest;
        }

        if (requestParameters.newest !== undefined) {
            queryParameters['newest'] = requestParameters.newest;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("m2m", []);
        }

        const response = await this.request({
            path: `/management/usage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UsageResponseFromJSON(jsonValue));
    }

    /**
     */
    async managementUsageGet(requestParameters: ManagementUsageGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsageResponse> {
        const response = await this.managementUsageGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ManagementUsageGetTypeEnum = {
    Consent: 'CONSENT',
    Insights: 'INSIGHTS'
} as const;
export type ManagementUsageGetTypeEnum = typeof ManagementUsageGetTypeEnum[keyof typeof ManagementUsageGetTypeEnum];
/**
 * @export
 */
export const ManagementUsageGetDimensionEnum = {
    Hourly: 'HOURLY',
    Daily: 'DAILY',
    Monthly: 'MONTHLY',
    Yearly: 'YEARLY'
} as const;
export type ManagementUsageGetDimensionEnum = typeof ManagementUsageGetDimensionEnum[keyof typeof ManagementUsageGetDimensionEnum];
/**
 * @export
 */
export const ManagementUsageGetRelativeEnum = {
    Today: 'TODAY',
    Yesterday: 'YESTERDAY',
    ThisWeek: 'THIS_WEEK',
    LastWeek: 'LAST_WEEK',
    ThisMonth: 'THIS_MONTH',
    LastMonth: 'LAST_MONTH',
    ThisYear: 'THIS_YEAR',
    LastYear: 'LAST_YEAR'
} as const;
export type ManagementUsageGetRelativeEnum = typeof ManagementUsageGetRelativeEnum[keyof typeof ManagementUsageGetRelativeEnum];
/**
 * @export
 */
export const ManagementUsageGetGroupEnum = {
    None: 'NONE',
    ActiveConsumer: 'ACTIVE_CONSUMER',
    Consumer: 'CONSUMER',
    DataHolder: 'DATA_HOLDER',
    UseCase: 'USE_CASE',
    ConsentStage: 'CONSENT_STAGE'
} as const;
export type ManagementUsageGetGroupEnum = typeof ManagementUsageGetGroupEnum[keyof typeof ManagementUsageGetGroupEnum];
