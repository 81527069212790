/* tslint:disable */
/* eslint-disable */
/**
 * Adatree REST API for managing Software Products
 * DCR Management APIs allow ADR customers to * enable / disable software products after it is LIVE in ACCC registry and synced back into Adatree ADR platform * provides a way to soft launch software products or take software products offline 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MetaBanking
 */
export interface MetaBanking {
    /**
     * DEPRECATED. a list of bsb prefixes for a data-holder
     * @type {Array<string>}
     * @memberof MetaBanking
     */
    bsbPrefix?: Array<string>;
}

/**
 * Check if a given object implements the MetaBanking interface.
 */
export function instanceOfMetaBanking(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MetaBankingFromJSON(json: any): MetaBanking {
    return MetaBankingFromJSONTyped(json, false);
}

export function MetaBankingFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaBanking {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bsbPrefix': !exists(json, 'bsbPrefix') ? undefined : json['bsbPrefix'],
    };
}

export function MetaBankingToJSON(value?: MetaBanking | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bsbPrefix': value.bsbPrefix,
    };
}

