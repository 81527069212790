/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConsentActivatedResponse,
  ConsentAuthorization,
  ConsentAuthorizationRequest,
  ConsentEventList,
  ConsentResponse,
  ConsentsList,
  CreateConsent,
  DataHolderAuthorizationResponse,
  ResponseErrorList,
  UpdateConsent,
} from '../models';
import {
    ConsentActivatedResponseFromJSON,
    ConsentActivatedResponseToJSON,
    ConsentAuthorizationFromJSON,
    ConsentAuthorizationToJSON,
    ConsentAuthorizationRequestFromJSON,
    ConsentAuthorizationRequestToJSON,
    ConsentEventListFromJSON,
    ConsentEventListToJSON,
    ConsentResponseFromJSON,
    ConsentResponseToJSON,
    ConsentsListFromJSON,
    ConsentsListToJSON,
    CreateConsentFromJSON,
    CreateConsentToJSON,
    DataHolderAuthorizationResponseFromJSON,
    DataHolderAuthorizationResponseToJSON,
    ResponseErrorListFromJSON,
    ResponseErrorListToJSON,
    UpdateConsentFromJSON,
    UpdateConsentToJSON,
} from '../models';

export interface CompleteDataHolderAuthorizationRequest {
    consentId: string;
    adatreeConsumerUserAgent?: string;
    adatreeConsumerIpAddress?: string;
    dataHolderAuthorizationResponse?: DataHolderAuthorizationResponse;
}

export interface CreateConsentRequest {
    adatreeConsumerUserAgent?: string;
    adatreeConsumerIpAddress?: string;
    createConsent?: CreateConsent;
}

export interface FindAllConsentsRequest {
    consumerId?: string;
    consumerEmail?: string;
    consentId?: string;
    cdrArrangementId?: string;
    status?: FindAllConsentsStatusEnum;
    accessFrequency?: FindAllConsentsAccessFrequencyEnum;
    postUsageAction?: FindAllConsentsPostUsageActionEnum;
    directMarketingAllowed?: boolean;
    useCases?: Array<string>;
    dataHolderBrandId?: string;
    oldestCreated?: string;
    newestCreated?: string;
    oldestRevoked?: string;
    newestRevoked?: string;
    oldestSharingEndDate?: string;
    newestSharingEndDate?: string;
    externalId?: string;
    metadataFilter?: string;
}

export interface FindAllConsentsV2Request {
    consumerId?: string;
    consumerEmail?: string;
    consentId?: string;
    cdrArrangementId?: string;
    status?: FindAllConsentsV2StatusEnum;
    accessFrequency?: FindAllConsentsV2AccessFrequencyEnum;
    postUsageAction?: FindAllConsentsV2PostUsageActionEnum;
    directMarketingAllowed?: boolean;
    useCaseIds?: Array<string>;
    dataHolderBrandId?: string;
    oldestCreated?: string;
    newestCreated?: string;
    oldestRevoked?: string;
    newestRevoked?: string;
    oldestCollectionEndDate?: string;
    newestCollectionEndDate?: string;
    externalId?: string;
    metadataFilter?: string;
    page?: number;
    pageSize?: number;
}

export interface FindConsentRequest {
    consentId: string;
}

export interface GetAuthorizationForConsentRequest {
    consentId: string;
    state?: string;
    redirectUri?: string;
}

export interface GetConsentEventsRequest {
    oldest?: string;
    newest?: string;
    consentId?: string;
    page?: number;
    pageSize?: number;
}

export interface PatchAuthorizationForConsentRequest {
    consentId: string;
    consentAuthorizationRequest?: ConsentAuthorizationRequest;
}

export interface RevokeConsentRequest {
    consentId: string;
}

export interface RevokeConsentsRequest {
    requestBody?: Array<string>;
}

export interface UpdateConsentRequest {
    consentId: string;
    updateConsent?: UpdateConsent;
}

/**
 * 
 */
export class ConsentApi extends runtime.BaseAPI {

    /**
     * At the completing of the consent process at a Data Holder, the consumer will be redirected back to the ADR system with a response JWT payload. This API takes that payload and exchanges it for a Refresh Token which is the final step in activating a consent 
     * Process the response from the Data Holder consent process
     */
    async completeDataHolderAuthorizationRaw(requestParameters: CompleteDataHolderAuthorizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConsentActivatedResponse>> {
        if (requestParameters.consentId === null || requestParameters.consentId === undefined) {
            throw new runtime.RequiredError('consentId','Required parameter requestParameters.consentId was null or undefined when calling completeDataHolderAuthorization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.adatreeConsumerUserAgent !== undefined && requestParameters.adatreeConsumerUserAgent !== null) {
            headerParameters['Adatree-Consumer-User-Agent'] = String(requestParameters.adatreeConsumerUserAgent);
        }

        if (requestParameters.adatreeConsumerIpAddress !== undefined && requestParameters.adatreeConsumerIpAddress !== null) {
            headerParameters['Adatree-Consumer-Ip-Address'] = String(requestParameters.adatreeConsumerIpAddress);
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("m2m", []);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/consents/{consentId}/authorization`.replace(`{${"consentId"}}`, encodeURIComponent(String(requestParameters.consentId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DataHolderAuthorizationResponseToJSON(requestParameters.dataHolderAuthorizationResponse),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConsentActivatedResponseFromJSON(jsonValue));
    }

    /**
     * At the completing of the consent process at a Data Holder, the consumer will be redirected back to the ADR system with a response JWT payload. This API takes that payload and exchanges it for a Refresh Token which is the final step in activating a consent 
     * Process the response from the Data Holder consent process
     */
    async completeDataHolderAuthorization(requestParameters: CompleteDataHolderAuthorizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConsentActivatedResponse> {
        const response = await this.completeDataHolderAuthorizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a consent record for consumer
     * Create Consent
     */
    async createConsentRaw(requestParameters: CreateConsentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConsentResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.adatreeConsumerUserAgent !== undefined && requestParameters.adatreeConsumerUserAgent !== null) {
            headerParameters['Adatree-Consumer-User-Agent'] = String(requestParameters.adatreeConsumerUserAgent);
        }

        if (requestParameters.adatreeConsumerIpAddress !== undefined && requestParameters.adatreeConsumerIpAddress !== null) {
            headerParameters['Adatree-Consumer-Ip-Address'] = String(requestParameters.adatreeConsumerIpAddress);
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("m2m", ["consents:write"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/consents`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateConsentToJSON(requestParameters.createConsent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConsentResponseFromJSON(jsonValue));
    }

    /**
     * Create a consent record for consumer
     * Create Consent
     */
    async createConsent(requestParameters: CreateConsentRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConsentResponse> {
        const response = await this.createConsentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all consent records for this consumer
     * Get Consents
     */
    async findAllConsentsRaw(requestParameters: FindAllConsentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ConsentResponse>>> {
        const queryParameters: any = {};

        if (requestParameters.consumerId !== undefined) {
            queryParameters['consumerId'] = requestParameters.consumerId;
        }

        if (requestParameters.consumerEmail !== undefined) {
            queryParameters['consumerEmail'] = requestParameters.consumerEmail;
        }

        if (requestParameters.consentId !== undefined) {
            queryParameters['consentId'] = requestParameters.consentId;
        }

        if (requestParameters.cdrArrangementId !== undefined) {
            queryParameters['cdrArrangementId'] = requestParameters.cdrArrangementId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.accessFrequency !== undefined) {
            queryParameters['accessFrequency'] = requestParameters.accessFrequency;
        }

        if (requestParameters.postUsageAction !== undefined) {
            queryParameters['postUsageAction'] = requestParameters.postUsageAction;
        }

        if (requestParameters.directMarketingAllowed !== undefined) {
            queryParameters['directMarketingAllowed'] = requestParameters.directMarketingAllowed;
        }

        if (requestParameters.useCases) {
            queryParameters['useCases'] = requestParameters.useCases;
        }

        if (requestParameters.dataHolderBrandId !== undefined) {
            queryParameters['dataHolderBrandId'] = requestParameters.dataHolderBrandId;
        }

        if (requestParameters.oldestCreated !== undefined) {
            queryParameters['oldestCreated'] = requestParameters.oldestCreated;
        }

        if (requestParameters.newestCreated !== undefined) {
            queryParameters['newestCreated'] = requestParameters.newestCreated;
        }

        if (requestParameters.oldestRevoked !== undefined) {
            queryParameters['oldestRevoked'] = requestParameters.oldestRevoked;
        }

        if (requestParameters.newestRevoked !== undefined) {
            queryParameters['newestRevoked'] = requestParameters.newestRevoked;
        }

        if (requestParameters.oldestSharingEndDate !== undefined) {
            queryParameters['oldestSharingEndDate'] = requestParameters.oldestSharingEndDate;
        }

        if (requestParameters.newestSharingEndDate !== undefined) {
            queryParameters['newestSharingEndDate'] = requestParameters.newestSharingEndDate;
        }

        if (requestParameters.externalId !== undefined) {
            queryParameters['externalId'] = requestParameters.externalId;
        }

        if (requestParameters.metadataFilter !== undefined) {
            queryParameters['metadataFilter'] = requestParameters.metadataFilter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("m2m", ["consents:read"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/consents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConsentResponseFromJSON));
    }

    /**
     * Get all consent records for this consumer
     * Get Consents
     */
    async findAllConsents(requestParameters: FindAllConsentsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ConsentResponse>> {
        const response = await this.findAllConsentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all consent records for this consumer
     * Get Consents
     */
    async findAllConsentsV2Raw(requestParameters: FindAllConsentsV2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConsentsList>> {
        const queryParameters: any = {};

        if (requestParameters.consumerId !== undefined) {
            queryParameters['consumerId'] = requestParameters.consumerId;
        }

        if (requestParameters.consumerEmail !== undefined) {
            queryParameters['consumerEmail'] = requestParameters.consumerEmail;
        }

        if (requestParameters.consentId !== undefined) {
            queryParameters['consentId'] = requestParameters.consentId;
        }

        if (requestParameters.cdrArrangementId !== undefined) {
            queryParameters['cdrArrangementId'] = requestParameters.cdrArrangementId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.accessFrequency !== undefined) {
            queryParameters['accessFrequency'] = requestParameters.accessFrequency;
        }

        if (requestParameters.postUsageAction !== undefined) {
            queryParameters['postUsageAction'] = requestParameters.postUsageAction;
        }

        if (requestParameters.directMarketingAllowed !== undefined) {
            queryParameters['directMarketingAllowed'] = requestParameters.directMarketingAllowed;
        }

        if (requestParameters.useCaseIds) {
            queryParameters['useCaseIds'] = requestParameters.useCaseIds;
        }

        if (requestParameters.dataHolderBrandId !== undefined) {
            queryParameters['dataHolderBrandId'] = requestParameters.dataHolderBrandId;
        }

        if (requestParameters.oldestCreated !== undefined) {
            queryParameters['oldestCreated'] = requestParameters.oldestCreated;
        }

        if (requestParameters.newestCreated !== undefined) {
            queryParameters['newestCreated'] = requestParameters.newestCreated;
        }

        if (requestParameters.oldestRevoked !== undefined) {
            queryParameters['oldestRevoked'] = requestParameters.oldestRevoked;
        }

        if (requestParameters.newestRevoked !== undefined) {
            queryParameters['newestRevoked'] = requestParameters.newestRevoked;
        }

        if (requestParameters.oldestCollectionEndDate !== undefined) {
            queryParameters['oldestCollectionEndDate'] = requestParameters.oldestCollectionEndDate;
        }

        if (requestParameters.newestCollectionEndDate !== undefined) {
            queryParameters['newestCollectionEndDate'] = requestParameters.newestCollectionEndDate;
        }

        if (requestParameters.externalId !== undefined) {
            queryParameters['externalId'] = requestParameters.externalId;
        }

        if (requestParameters.metadataFilter !== undefined) {
            queryParameters['metadataFilter'] = requestParameters.metadataFilter;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['pageSize'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("m2m", ["consents:read"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/consents/v2`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConsentsListFromJSON(jsonValue));
    }

    /**
     * Get all consent records for this consumer
     * Get Consents
     */
    async findAllConsentsV2(requestParameters: FindAllConsentsV2Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConsentsList> {
        const response = await this.findAllConsentsV2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve a single consent record for this consumer
     * Get Consent
     */
    async findConsentRaw(requestParameters: FindConsentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConsentResponse>> {
        if (requestParameters.consentId === null || requestParameters.consentId === undefined) {
            throw new runtime.RequiredError('consentId','Required parameter requestParameters.consentId was null or undefined when calling findConsent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("m2m", ["consents:read"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/consents/{consentId}`.replace(`{${"consentId"}}`, encodeURIComponent(String(requestParameters.consentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConsentResponseFromJSON(jsonValue));
    }

    /**
     * Retrieve a single consent record for this consumer
     * Get Consent
     */
    async findConsent(requestParameters: FindConsentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConsentResponse> {
        const response = await this.findConsentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the authorization link for a Consent
     * Get the authorization link for a Consent
     */
    async getAuthorizationForConsentRaw(requestParameters: GetAuthorizationForConsentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConsentAuthorization>> {
        if (requestParameters.consentId === null || requestParameters.consentId === undefined) {
            throw new runtime.RequiredError('consentId','Required parameter requestParameters.consentId was null or undefined when calling getAuthorizationForConsent.');
        }

        const queryParameters: any = {};

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.redirectUri !== undefined) {
            queryParameters['redirectUri'] = requestParameters.redirectUri;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("m2m", ["consents:read"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/consents/{consentId}/authorization`.replace(`{${"consentId"}}`, encodeURIComponent(String(requestParameters.consentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConsentAuthorizationFromJSON(jsonValue));
    }

    /**
     * Get the authorization link for a Consent
     * Get the authorization link for a Consent
     */
    async getAuthorizationForConsent(requestParameters: GetAuthorizationForConsentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConsentAuthorization> {
        const response = await this.getAuthorizationForConsentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve consent events
     */
    async getConsentEventsRaw(requestParameters: GetConsentEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConsentEventList>> {
        const queryParameters: any = {};

        if (requestParameters.oldest !== undefined) {
            queryParameters['oldest'] = requestParameters.oldest;
        }

        if (requestParameters.newest !== undefined) {
            queryParameters['newest'] = requestParameters.newest;
        }

        if (requestParameters.consentId !== undefined) {
            queryParameters['consentId'] = requestParameters.consentId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page-size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("m2m", []);
        }

        const response = await this.request({
            path: `/consents/events`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConsentEventListFromJSON(jsonValue));
    }

    /**
     * Retrieve consent events
     */
    async getConsentEvents(requestParameters: GetConsentEventsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConsentEventList> {
        const response = await this.getConsentEventsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns an authorization request link from the Data Holder consent process.
     * Create an authorization link
     */
    async patchAuthorizationForConsentRaw(requestParameters: PatchAuthorizationForConsentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConsentAuthorization>> {
        if (requestParameters.consentId === null || requestParameters.consentId === undefined) {
            throw new runtime.RequiredError('consentId','Required parameter requestParameters.consentId was null or undefined when calling patchAuthorizationForConsent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("m2m", ["consents:read"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/consents/{consentId}/authorization`.replace(`{${"consentId"}}`, encodeURIComponent(String(requestParameters.consentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConsentAuthorizationRequestToJSON(requestParameters.consentAuthorizationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConsentAuthorizationFromJSON(jsonValue));
    }

    /**
     * Returns an authorization request link from the Data Holder consent process.
     * Create an authorization link
     */
    async patchAuthorizationForConsent(requestParameters: PatchAuthorizationForConsentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConsentAuthorization> {
        const response = await this.patchAuthorizationForConsentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Revoke a single consent record for this consumer
     * Revoke Consent
     */
    async revokeConsentRaw(requestParameters: RevokeConsentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.consentId === null || requestParameters.consentId === undefined) {
            throw new runtime.RequiredError('consentId','Required parameter requestParameters.consentId was null or undefined when calling revokeConsent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("m2m", ["consents:write"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/consents/{consentId}`.replace(`{${"consentId"}}`, encodeURIComponent(String(requestParameters.consentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Revoke a single consent record for this consumer
     * Revoke Consent
     */
    async revokeConsent(requestParameters: RevokeConsentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.revokeConsentRaw(requestParameters, initOverrides);
    }

    /**
     * Revoke many consent records owned by a resource owner
     * Bulk Revoke Consent
     */
    async revokeConsentsRaw(requestParameters: RevokeConsentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("m2m", []);
        }

        const response = await this.request({
            path: `/consents/revoke`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Revoke many consent records owned by a resource owner
     * Bulk Revoke Consent
     */
    async revokeConsents(requestParameters: RevokeConsentsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.revokeConsentsRaw(requestParameters, initOverrides);
    }

    /**
     * <ul><li>Update postUsageAction, directMarketing option or sharing end date when a dashboard token is received</li><br/> <li>Update externalId when a machine (backchannel) token is received</li></ul> 
     * Update a consent via dashboard or back channel
     */
    async updateConsentRaw(requestParameters: UpdateConsentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.consentId === null || requestParameters.consentId === undefined) {
            throw new runtime.RequiredError('consentId','Required parameter requestParameters.consentId was null or undefined when calling updateConsent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("m2m", ["consents:write"]);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/consents/{consentId}`.replace(`{${"consentId"}}`, encodeURIComponent(String(requestParameters.consentId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateConsentToJSON(requestParameters.updateConsent),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * <ul><li>Update postUsageAction, directMarketing option or sharing end date when a dashboard token is received</li><br/> <li>Update externalId when a machine (backchannel) token is received</li></ul> 
     * Update a consent via dashboard or back channel
     */
    async updateConsent(requestParameters: UpdateConsentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateConsentRaw(requestParameters, initOverrides);
    }

}

/**
 * @export
 */
export const FindAllConsentsStatusEnum = {
    Requested: 'REQUESTED',
    Active: 'ACTIVE',
    Revoked: 'REVOKED',
    Expired: 'EXPIRED'
} as const;
export type FindAllConsentsStatusEnum = typeof FindAllConsentsStatusEnum[keyof typeof FindAllConsentsStatusEnum];
/**
 * @export
 */
export const FindAllConsentsAccessFrequencyEnum = {
    OnceOff: 'ONCE_OFF',
    Ongoing: 'ONGOING'
} as const;
export type FindAllConsentsAccessFrequencyEnum = typeof FindAllConsentsAccessFrequencyEnum[keyof typeof FindAllConsentsAccessFrequencyEnum];
/**
 * @export
 */
export const FindAllConsentsPostUsageActionEnum = {
    Deletion: 'DELETION',
    DeIdentification: 'DE_IDENTIFICATION'
} as const;
export type FindAllConsentsPostUsageActionEnum = typeof FindAllConsentsPostUsageActionEnum[keyof typeof FindAllConsentsPostUsageActionEnum];
/**
 * @export
 */
export const FindAllConsentsV2StatusEnum = {
    Requested: 'REQUESTED',
    Active: 'ACTIVE',
    Revoked: 'REVOKED',
    Expired: 'EXPIRED'
} as const;
export type FindAllConsentsV2StatusEnum = typeof FindAllConsentsV2StatusEnum[keyof typeof FindAllConsentsV2StatusEnum];
/**
 * @export
 */
export const FindAllConsentsV2AccessFrequencyEnum = {
    OnceOff: 'ONCE_OFF',
    Ongoing: 'ONGOING'
} as const;
export type FindAllConsentsV2AccessFrequencyEnum = typeof FindAllConsentsV2AccessFrequencyEnum[keyof typeof FindAllConsentsV2AccessFrequencyEnum];
/**
 * @export
 */
export const FindAllConsentsV2PostUsageActionEnum = {
    Deletion: 'DELETION',
    DeIdentification: 'DE_IDENTIFICATION'
} as const;
export type FindAllConsentsV2PostUsageActionEnum = typeof FindAllConsentsV2PostUsageActionEnum[keyof typeof FindAllConsentsV2PostUsageActionEnum];
