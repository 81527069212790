import React, { useLocation } from 'react-router-dom';
import posthog from 'posthog-js';
import { createContext, ReactElement, useContext, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useUser } from './user.context';
import { Logger } from '@adatree/atomic-components';

enum AnalyticsEvents {
  PAGE_LOAD = '$pageview',
  BUTTON_CLICK = 'Button click',
}

export enum AnalyticsComponentIds {
  INSIGHTS_RPT_CREATE_BT = 'Insights report create button',
  INSIGHTS_RPT_CREATE_EMAIL_BT = 'Insights report create email button',
  INSIGHTS_RPT_SEARCH_BT = 'Insights report search button',
  INSIGHTS_RPT_SEARCH_RESET_BT = 'Insights report search reset button',
  INSIGHTS_RPT_SEARCH_TOGGLE_BT = 'Insights report search toggle button',
  DH_SESSION_SEARCH_RESET_BT = 'Data Holder Sessions search reset button',
  DH_SESSION_SEARCH_TOGGLE_BT = 'Data Holder Sessions search toggle button',
  DH_INTERACTIONS_SEARCH_RESET_BT = 'Data Holder Interactions search reset button',
  DH_INTERACTIONS_SEARCH_TOGGLE_BT = 'Data Holder Interactions search toggle button',
  CONSENT_ADV_SEARCH_BT = 'Consent advanced search button',
  CONSENT_ADV_SEARCH_RESET_BT = 'Consent advanced search reset button',
  CONSENT_ADV_SEARCH_TOGGLE_BT = 'Consent advanced search toggle button',
  TUTORIAL_WALKTHROUGH_CONTINUE_BT = 'Tutorial walkthrough continue button',
}

export enum AnalyticsAction {
  BACK = 'Back',
  CLOSE = 'Close',
  CREATE = 'Create',
  NEXT = 'Next',
  OPEN = 'Open',
  RESET = 'Reset',
  SUBMIT = 'Submit',
}

interface ContextProps {
  trackButtonClick: (id: AnalyticsComponentIds, action: AnalyticsAction) => void;
}

const AnalyticsContext = createContext<ContextProps | undefined>(undefined);

const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error('useAnalytics must be used within a AnalyticsContext');
  }
  return context;
};

interface ProviderProps {
  children: ReactElement | ReactElement[];
}

const AnalyticsProvider = ({ children }: ProviderProps) => {
  let userId = 'unknown-user';
  const auth = useAuth();
  const user = auth.user;
  const appVersion = process.env.REACT_APP_VERSION || 'no-version-set';
  const analyticsKey = process.env.REACT_APP_ANALYTICS_KEY;
  const location = useLocation();
  const { tenantName, resourceOwnerId } = useUser();

  useEffect(() => {
    if (user?.profile?.email) {
      userId = user.profile.email;
    }

    if (analyticsKey) {
      posthog.init(analyticsKey, {
        api_host: 'https://eu.i.posthog.com',
        capture_pageview: false,
      });

      posthog.identify(userId, {
        app_version: appVersion,
        tenant_name: tenantName,
        resource_owner_id: resourceOwnerId,
      });
    } else {
      Logger.info('Analytics key not set. Analytics tracking is turned off');
    }
  }, []);

  // Track pageviews
  useEffect(() => {
    if (posthog) {
      posthog.capture('$pageview', {
        $current_url: window.location.href,
      });
    }
  }, [location]);

  const track = (event: string, properties: Record<string, string | string[]>) => {
    console.log('Track', event, properties);
    // Call Analytics tracker
    // ...
    // ...
  };

  const trackButtonClick = (id: AnalyticsComponentIds, action: AnalyticsAction) => {
    track(AnalyticsEvents.BUTTON_CLICK, { Action: action, 'Component ID': id });
  };

  return (
    <>
      <AnalyticsContext.Provider
        value={{
          trackButtonClick,
        }}
      >
        {children}
      </AnalyticsContext.Provider>
    </>
  );
};

export { AnalyticsProvider, useAnalytics };
