import React from 'react';
import { CircularProgress } from '@mui/material';
import { CenterLayout } from '../../atoms/center-layout/center-layout.atom';

export const LoginPage = () => {
  window.location.replace(process.env.REACT_APP_AUTHORITY ?? '');

  return (
    <CenterLayout>
      <CircularProgress />
    </CenterLayout>
  );
};
