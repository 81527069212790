/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MetaPaginated
 */
export interface MetaPaginated {
    /**
     * The total number of pages in the full set.
     * @type {number}
     * @memberof MetaPaginated
     */
    totalPages: number;
    /**
     * The total number of records in the full set.
     * @type {number}
     * @memberof MetaPaginated
     */
    totalRecords: number;
}

/**
 * Check if a given object implements the MetaPaginated interface.
 */
export function instanceOfMetaPaginated(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "totalPages" in value;
    isInstance = isInstance && "totalRecords" in value;

    return isInstance;
}

export function MetaPaginatedFromJSON(json: any): MetaPaginated {
    return MetaPaginatedFromJSONTyped(json, false);
}

export function MetaPaginatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaPaginated {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPages': json['totalPages'],
        'totalRecords': json['totalRecords'],
    };
}

export function MetaPaginatedToJSON(value?: MetaPaginated | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalPages': value.totalPages,
        'totalRecords': value.totalRecords,
    };
}

