import React, { ReactElement } from 'react';
import { useUser } from '../context/user.context';
import { ApiProvider as SdkApiProvider } from '@adatree/react-api-sdk';
import { BootstrapProvider } from './bootstrap.provider';
import { AnalyticsProvider } from '@adatree/atomic-components';
interface Props {
  accessToken: string;
  children: ReactElement | ReactElement[];
}

export const ApiProvider = ({ accessToken, children }: Props) => {
  const { apiUrl } = useUser();

  return (
    <SdkApiProvider accessToken={accessToken} apiUrl={apiUrl}>
      <AnalyticsProvider>
        <BootstrapProvider>{children}</BootstrapProvider>
      </AnalyticsProvider>
    </SdkApiProvider>
  );
};
