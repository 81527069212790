/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * consent grantee that will access CDR data
 * @export
 * @interface GranteeRequest
 */
export interface GranteeRequest {
    /**
     * grantee name
     * @type {string}
     * @memberof GranteeRequest
     */
    name: string;
    /**
     * ACL number of consent grantee
     * @type {string}
     * @memberof GranteeRequest
     */
    licenceNumber: string;
}

/**
 * Check if a given object implements the GranteeRequest interface.
 */
export function instanceOfGranteeRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "licenceNumber" in value;

    return isInstance;
}

export function GranteeRequestFromJSON(json: any): GranteeRequest {
    return GranteeRequestFromJSONTyped(json, false);
}

export function GranteeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GranteeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'licenceNumber': json['licenceNumber'],
    };
}

export function GranteeRequestToJSON(value?: GranteeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'licenceNumber': value.licenceNumber,
    };
}

