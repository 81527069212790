/* tslint:disable */
/* eslint-disable */
/**
 * Adatree REST API for managing Software Products
 * DCR Management APIs allow ADR customers to * enable / disable software products after it is LIVE in ACCC registry and synced back into Adatree ADR platform * provides a way to soft launch software products or take software products offline 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TenantFeatures
 */
export interface TenantFeatures {
    /**
     * 
     * @type {boolean}
     * @memberof TenantFeatures
     */
    dataHolderTest: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TenantFeatures
     */
    grantee: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TenantFeatures
     */
    allowConcurrentConsents: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TenantFeatures
     */
    useAdrBranding: boolean;
}

/**
 * Check if a given object implements the TenantFeatures interface.
 */
export function instanceOfTenantFeatures(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dataHolderTest" in value;
    isInstance = isInstance && "grantee" in value;
    isInstance = isInstance && "allowConcurrentConsents" in value;
    isInstance = isInstance && "useAdrBranding" in value;

    return isInstance;
}

export function TenantFeaturesFromJSON(json: any): TenantFeatures {
    return TenantFeaturesFromJSONTyped(json, false);
}

export function TenantFeaturesFromJSONTyped(json: any, ignoreDiscriminator: boolean): TenantFeatures {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataHolderTest': json['dataHolderTest'],
        'grantee': json['grantee'],
        'allowConcurrentConsents': json['allowConcurrentConsents'],
        'useAdrBranding': json['useAdrBranding'],
    };
}

export function TenantFeaturesToJSON(value?: TenantFeatures | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataHolderTest': value.dataHolderTest,
        'grantee': value.grantee,
        'allowConcurrentConsents': value.allowConcurrentConsents,
        'useAdrBranding': value.useAdrBranding,
    };
}

