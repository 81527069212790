/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutsourcedServiceProvider
 */
export interface OutsourcedServiceProvider {
    /**
     * The consumer facing name of the service provider.
     * @type {string}
     * @memberof OutsourcedServiceProvider
     */
    providerName: string;
    /**
     * The consumer facing description of what the service provider does as part of supporting the use case.
     * @type {string}
     * @memberof OutsourcedServiceProvider
     */
    serviceDescription: string;
    /**
     * The ACCC issued Accreditation ID if applicable. Normally only applied to OSPs for data collection e.g. Adatree.
     * @type {string}
     * @memberof OutsourcedServiceProvider
     */
    accreditationId?: string;
    /**
     * The logo URI of the data holder you wish the consumer to authenticate with.
     * @type {string}
     * @memberof OutsourcedServiceProvider
     */
    cdrPolicyUri?: string;
}

/**
 * Check if a given object implements the OutsourcedServiceProvider interface.
 */
export function instanceOfOutsourcedServiceProvider(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "providerName" in value;
    isInstance = isInstance && "serviceDescription" in value;

    return isInstance;
}

export function OutsourcedServiceProviderFromJSON(json: any): OutsourcedServiceProvider {
    return OutsourcedServiceProviderFromJSONTyped(json, false);
}

export function OutsourcedServiceProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutsourcedServiceProvider {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'providerName': json['providerName'],
        'serviceDescription': json['serviceDescription'],
        'accreditationId': !exists(json, 'accreditationId') ? undefined : json['accreditationId'],
        'cdrPolicyUri': !exists(json, 'cdrPolicyUri') ? undefined : json['cdrPolicyUri'],
    };
}

export function OutsourcedServiceProviderToJSON(value?: OutsourcedServiceProvider | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'providerName': value.providerName,
        'serviceDescription': value.serviceDescription,
        'accreditationId': value.accreditationId,
        'cdrPolicyUri': value.cdrPolicyUri,
    };
}

