import { ADMIN_ROLES, AUTH_FEATURES, CUD_ROLES, DATA_HOLDER_TESTER_ROLES } from '../../constants/authorization.const';

export const hasAuthorization = (userAuthorization: string[], requiredAuthorization: string[]): boolean => {
  const isAuthorization = requiredAuthorization.some((reqAuth) => {
    return userAuthorization.includes(reqAuth);
  });

  return isAuthorization;
};

export const isAdmin = (userAuthorization: string[]): boolean => {
  return hasAuthorization(userAuthorization, ADMIN_ROLES);
};

export const isDataHolderTestingFeatureEnabled = (userAuthorization: string[]): boolean => {
  return hasAuthorization(userAuthorization, [AUTH_FEATURES.DATA_HOLDER_TESTING]);
};

export const isDataHolderTester = (userAuthorization: string[]): boolean => {
  return hasAuthorization(userAuthorization, DATA_HOLDER_TESTER_ROLES);
};

export const isCud = (userAuthorization: string[]): boolean => {
  return hasAuthorization(userAuthorization, CUD_ROLES);
};
