/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PasswordlessLoginLinkRequest
 */
export interface PasswordlessLoginLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordlessLoginLinkRequest
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PasswordlessLoginLinkRequest
     */
    notifyConsumer?: boolean;
    /**
     * additional claims that will be added to the id_token after a user successfully creates a session using a passwordless login link
     * @type {{ [key: string]: any; }}
     * @memberof PasswordlessLoginLinkRequest
     */
    additionalClaims?: { [key: string]: any; };
    /**
     * the session data schema ID to validate the additionalClaims field against. Optional for machine authorization tokens. Must be present for other authorization token types. If not present then the additionalClaims stored will not be validated.
     * @type {string}
     * @memberof PasswordlessLoginLinkRequest
     */
    schemaId?: string;
}

/**
 * Check if a given object implements the PasswordlessLoginLinkRequest interface.
 */
export function instanceOfPasswordlessLoginLinkRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PasswordlessLoginLinkRequestFromJSON(json: any): PasswordlessLoginLinkRequest {
    return PasswordlessLoginLinkRequestFromJSONTyped(json, false);
}

export function PasswordlessLoginLinkRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PasswordlessLoginLinkRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'notifyConsumer': !exists(json, 'notifyConsumer') ? undefined : json['notifyConsumer'],
        'additionalClaims': !exists(json, 'additionalClaims') ? undefined : json['additionalClaims'],
        'schemaId': !exists(json, 'schemaId') ? undefined : json['schemaId'],
    };
}

export function PasswordlessLoginLinkRequestToJSON(value?: PasswordlessLoginLinkRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'notifyConsumer': value.notifyConsumer,
        'additionalClaims': value.additionalClaims,
        'schemaId': value.schemaId,
    };
}

