import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { URL_PAGES } from '../../../app-logic/constants/url.const';
import { COLOUR } from '../../../app-logic/constants/colour.const';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../../../app-logic/context/user.context';
import { hasAuthorization } from '../../../app-logic/utils/authorization/authorization.util';
import { MenuItem, navMenuItems } from '../../../app-logic/constants/menu.const';
import { DownArrowIcon } from '../../atoms/icons/icons.atom';

interface Props {
  onSubMenuClick?: () => void;
}

export const SideMenu = ({ onSubMenuClick }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { authorization, resourceOwnerId } = useUser();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [active, setActive] = useState<string>();
  const [sideMenuItems, setSideMenuItems] = useState<MenuItem[]>([]);
  const iconPaddingLeft = 4;
  const iconPaddingRight = 2;

  useEffect(() => {
    const paths = pathname.trim().split('/');
    const pageLevel = paths.length - 1;
    const firstPath = '/' + paths[1];

    // Get the correct side menu items
    setSideMenuItems(navMenuItems);

    // Expand the Parent
    for (const menuItem of navMenuItems) {
      if (firstPath === '/') {
        setExpanded(`panel-${menuItem.label}`);
        break;
      } else if (menuItem.path?.startsWith(firstPath)) {
        setExpanded(`panel-${menuItem.label}`);
        break;
      }
    }

    // Hightlight the correct side menu item
    for (const urlPageName in URL_PAGES) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const urlPage = URL_PAGES[urlPageName];

      if (pageLevel < 3 && pathname === urlPage.url) {
        setActive(urlPage.label);
        break;
      } else if (pageLevel >= 3 && urlPage.url === `${paths[0]}/${paths[1]}/${paths[2]}`) {
        setActive(urlPage.label);
        break;
      }
    }
  }, [pathname]);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
    if (onSubMenuClick) {
      onSubMenuClick();
    }
  };

  const renderParent = (parent: MenuItem) => {
    let show = false;

    if (!parent.mustHaveAuthorization) {
      show = true;
    } else {
      show = hasAuthorization([...authorization, resourceOwnerId], parent.mustHaveAuthorization);
    }

    if (show) {
      if (parent.children) {
        return renderParentWithChildren(parent);
      } else {
        return (
          <ListItemButton
            key={parent.label}
            sx={{
              pl: iconPaddingLeft,
              '.MuiTypography-root': { fontSize: '1.6rem' },
              backgroundColor: active === parent.label ? COLOUR.HOVER : 'transparent',
              '&:hover': { background: COLOUR.HOVER },
            }}
            onClick={() => {
              if (parent.path) {
                setActive(parent.label);
                navigate(parent.path);
              }
            }}
          >
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: iconPaddingRight }}>
                {parent.icon}
                <ListItemText primary={parent.label} />
              </Box>
            </>
          </ListItemButton>
        );
      }
    }
  };

  const renderParentWithChildren = (parent: MenuItem) => {
    const panelName = `panel-${parent.label}`;
    return (
      <Accordion
        key={panelName}
        expanded={expanded === panelName}
        onChange={handleChange(panelName)}
        elevation={0}
        disableGutters
        sx={{
          '& .MuiAccordionSummary-root:hover': { background: COLOUR.HOVER },
          '&:before': { background: 'transparent' },
        }}
      >
        <AccordionSummary
          expandIcon={<DownArrowIcon />}
          sx={{ pl: iconPaddingLeft, display: 'flex', justifyContent: 'start' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: iconPaddingRight }}>
            {parent.icon}
            {parent.label}
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ pt: 0, px: 0 }}>
          {parent.children?.map((item) => {
            return renderChild(item);
          })}
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderChild = (child: MenuItem) => {
    let show = false;

    if (!child.mustHaveAuthorization) {
      show = true;
    } else {
      show = hasAuthorization(authorization, child.mustHaveAuthorization);
    }
    return (
      <Box key={child.label}>
        {show && (
          <ListItemButton
            sx={{
              backgroundColor: active === child.label ? COLOUR.HOVER : 'transparent',
              '&:hover': { background: COLOUR.HOVER },
            }}
            onClick={() => {
              if (child.path) {
                setActive(child.label);
                navigate(child.path);
              }
            }}
          >
            <ListItemText
              sx={{ pl: 6 }}
              disableTypography
              primary={<Typography variant="body2">{child.label}</Typography>}
            />
          </ListItemButton>
        )}
      </Box>
    );
  };

  return (
    <Box>
      <List id="adt-side-nav">
        {sideMenuItems.map((item) => {
          return renderParent(item);
        })}
      </List>
    </Box>
  );
};
