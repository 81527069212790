import React from 'react';
import { HeaderBar } from '../../molecules/header-bar/header-bar.molecule';
import { LayoutBody } from './layout-body.organism';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { CenterLayout } from '../../atoms/center-layout/center-layout.atom';
import { ErrorMessage } from '@adatree/atomic-components';

interface Props {
  error: Error;
}

const fallbackRender = ({ error }: Props) => {
  return (
    <CenterLayout>
      <ErrorMessage message={error.message} code={error.name} />
    </CenterLayout>
  );
};

export const Layout = () => {
  return (
    <>
      <HeaderBar />
      <LayoutBody>
        <ErrorBoundary fallbackRender={fallbackRender}>
          <Outlet />
        </ErrorBoundary>
      </LayoutBody>
    </>
  );
};
