/* tslint:disable */
/* eslint-disable */
/**
 * Adatree REST API for managing Software Products
 * DCR Management APIs allow ADR customers to * enable / disable software products after it is LIVE in ACCC registry and synced back into Adatree ADR platform * provides a way to soft launch software products or take software products offline 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Industry } from './Industry';
import {
    IndustryFromJSON,
    IndustryFromJSONTyped,
    IndustryToJSON,
} from './Industry';

/**
 * allowed list of data holder ids
 * @export
 * @interface UpdateSoftwareProduct
 */
export interface UpdateSoftwareProduct {
    /**
     * a list of allowed data holder ids or an empty array to reset to all data holders
     * @type {Array<string>}
     * @memberof UpdateSoftwareProduct
     */
    dataHolderIds?: Array<string>;
    /**
     * a list of allowed industries or an empty list to reset to banking industry only(default)
     * @type {Array<Industry>}
     * @memberof UpdateSoftwareProduct
     */
    industries?: Array<Industry>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSoftwareProduct
     */
    enabled?: boolean;
}

/**
 * Check if a given object implements the UpdateSoftwareProduct interface.
 */
export function instanceOfUpdateSoftwareProduct(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateSoftwareProductFromJSON(json: any): UpdateSoftwareProduct {
    return UpdateSoftwareProductFromJSONTyped(json, false);
}

export function UpdateSoftwareProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSoftwareProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataHolderIds': !exists(json, 'dataHolderIds') ? undefined : json['dataHolderIds'],
        'industries': !exists(json, 'industries') ? undefined : ((json['industries'] as Array<any>).map(IndustryFromJSON)),
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
    };
}

export function UpdateSoftwareProductToJSON(value?: UpdateSoftwareProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataHolderIds': value.dataHolderIds,
        'industries': value.industries === undefined ? undefined : ((value.industries as Array<any>).map(IndustryToJSON)),
        'enabled': value.enabled,
    };
}

