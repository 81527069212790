import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { APP_CONSTANTS } from '../../../app-logic/constants/app.const';
import { CenterLayout } from '../center-layout/center-layout.atom';
import { CircularProgress, Typography } from '@mui/material';
import { ApiProvider } from '../../../app-logic/providers/api.provider';
import { UserProvider } from '../../../app-logic/context/user.context';
import { AnalyticsProvider } from '../../../app-logic/context/analytics.context';

export const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    if (!auth.isLoading && !auth.error && !auth.isAuthenticated) {
      // Save current URL including any parameters to return user to on callback
      localStorage.setItem(APP_CONSTANTS.storageKeys.returnUri, window.location.pathname + window.location.search);

      auth.signinRedirect();
    }
    if (auth.user?.access_token) {
      setAccessToken(auth.user?.access_token);
    }
  }, [auth]);

  switch (auth.activeNavigator) {
    case 'signinSilent':
      return (
        <CenterLayout>
          <CircularProgress />
        </CenterLayout>
      );
    case 'signoutRedirect':
      return (
        <CenterLayout>
          <CircularProgress />
        </CenterLayout>
      );
  }

  if (auth.isLoading) {
    return (
      <CenterLayout>
        <CircularProgress />
      </CenterLayout>
    );
  }

  if (auth.error) {
    return (
      <CenterLayout>
        <Typography>Oops... {auth.error.message}</Typography>
      </CenterLayout>
    );
  }

  if (auth.isAuthenticated && accessToken) {
    return (
      <UserProvider>
        <AnalyticsProvider>
          <ApiProvider accessToken={accessToken}>{children}</ApiProvider>
        </AnalyticsProvider>
      </UserProvider>
    );
  }

  return null;
};
