import React from 'react';
import { CircularProgress } from '@mui/material';
import { CenterLayout } from '../../atoms/center-layout/center-layout.atom';
import { useSearchParams } from 'react-router-dom';
import { ErrorMessage } from '../../atoms/error-message/error-message.atom';

export const AuthCallbackPage = () => {
  const [searchParams] = useSearchParams();
  const error = searchParams.get('error');
  const errorDescription = searchParams.get('error_description');

  return (
    <CenterLayout>
      <>
        {!error && <CircularProgress />}
        {error && (
          <ErrorMessage
            userFriendlyMessage={error ?? 'Error'}
            error={new Error(errorDescription ?? 'Authentication error')}
          />
        )}
      </>
    </CenterLayout>
  );
};
