import type {} from '@mui/x-data-grid/themeAugmentation';
import { COLOUR } from '../../app-logic/constants/colour.const';
import { ComponentsOverrides, Theme } from '@mui/material/styles';

const MuiDataGridOverride: ComponentsOverrides<Theme>['MuiDataGrid'] = {
  row: () => ({
    ':hover': { backgroundColor: COLOUR.HOVER },
  }),
};

export const extendTheme = {
  typography: {
    h1: {
      fontSize: '2.8rem',
    },
    h2: {
      fontSize: '2.2rem',
    },
    h3: {
      fontSize: '2.0rem',
    },
    h4: {
      fontSize: '1.8rem',
    },
    h5: {
      fontSize: '1.7rem',
    },
    h6: {
      fontSize: '1.6rem',
    },
    body1: {
      fontSize: '1.4rem',
    },
  },
  components: {
    MuiDataGrid: {
      styleOverrides: MuiDataGridOverride,
    },
  },
};
