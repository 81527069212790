/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConsentGrantee } from './ConsentGrantee';
import {
    ConsentGranteeFromJSON,
    ConsentGranteeFromJSONTyped,
    ConsentGranteeToJSON,
} from './ConsentGrantee';
import type { PostUsageAction } from './PostUsageAction';
import {
    PostUsageActionFromJSON,
    PostUsageActionFromJSONTyped,
    PostUsageActionToJSON,
} from './PostUsageAction';
import type { ServiceProvider } from './ServiceProvider';
import {
    ServiceProviderFromJSON,
    ServiceProviderFromJSONTyped,
    ServiceProviderToJSON,
} from './ServiceProvider';
import type { Status } from './Status';
import {
    StatusFromJSON,
    StatusFromJSONTyped,
    StatusToJSON,
} from './Status';
import type { UseCaseResponse } from './UseCaseResponse';
import {
    UseCaseResponseFromJSON,
    UseCaseResponseFromJSONTyped,
    UseCaseResponseToJSON,
} from './UseCaseResponse';

/**
 * 
 * @export
 * @interface ConsentResponse
 */
export interface ConsentResponse {
    /**
     * 
     * @type {number}
     * @memberof ConsentResponse
     */
    version?: number;
    /**
     * 
     * @type {string}
     * @memberof ConsentResponse
     */
    created?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentResponse
     */
    revoked?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentResponse
     */
    lastNotificationSentAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentResponse
     */
    firstDataCollection?: string;
    /**
     * 
     * @type {Status}
     * @memberof ConsentResponse
     */
    status?: Status;
    /**
     * 
     * @type {string}
     * @memberof ConsentResponse
     */
    sharingEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentResponse
     */
    consumerEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentResponse
     */
    dataHolderName?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentResponse
     */
    dataHolderBrandId?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentResponse
     */
    dataHolderLogoUri?: string;
    /**
     * 
     * @type {UseCaseResponse}
     * @memberof ConsentResponse
     */
    useCase?: UseCaseResponse;
    /**
     * 
     * @type {PostUsageAction}
     * @memberof ConsentResponse
     */
    postUsageAction?: PostUsageAction;
    /**
     * 
     * @type {string}
     * @memberof ConsentResponse
     */
    consumerId?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentResponse
     */
    cdrArrangementId?: string;
    /**
     * 
     * @type {string}
     * @memberof ConsentResponse
     */
    consentId: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConsentResponse
     */
    directMarketingAllowed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConsentResponse
     */
    consumerMobileNumber?: string;
    /**
     * Adatree's customer can supply an externalId when creating consents to associate with records in its own system
     * @type {string}
     * @memberof ConsentResponse
     */
    externalId?: string;
    /**
     * 
     * @type {ConsentGrantee}
     * @memberof ConsentResponse
     */
    grantee?: ConsentGrantee;
    /**
     * 
     * @type {ServiceProvider}
     * @memberof ConsentResponse
     */
    serviceProvider?: ServiceProvider;
    /**
     * metadata of the consent
     * @type {string}
     * @memberof ConsentResponse
     */
    metadata?: string;
}

/**
 * Check if a given object implements the ConsentResponse interface.
 */
export function instanceOfConsentResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "consentId" in value;

    return isInstance;
}

export function ConsentResponseFromJSON(json: any): ConsentResponse {
    return ConsentResponseFromJSONTyped(json, false);
}

export function ConsentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'version': !exists(json, 'version') ? undefined : json['version'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'revoked': !exists(json, 'revoked') ? undefined : json['revoked'],
        'lastNotificationSentAt': !exists(json, 'lastNotificationSentAt') ? undefined : json['lastNotificationSentAt'],
        'firstDataCollection': !exists(json, 'firstDataCollection') ? undefined : json['firstDataCollection'],
        'status': !exists(json, 'status') ? undefined : StatusFromJSON(json['status']),
        'sharingEndDate': !exists(json, 'sharingEndDate') ? undefined : json['sharingEndDate'],
        'consumerEmail': !exists(json, 'consumerEmail') ? undefined : json['consumerEmail'],
        'dataHolderName': !exists(json, 'dataHolderName') ? undefined : json['dataHolderName'],
        'dataHolderBrandId': !exists(json, 'dataHolderBrandId') ? undefined : json['dataHolderBrandId'],
        'dataHolderLogoUri': !exists(json, 'dataHolderLogoUri') ? undefined : json['dataHolderLogoUri'],
        'useCase': !exists(json, 'useCase') ? undefined : UseCaseResponseFromJSON(json['useCase']),
        'postUsageAction': !exists(json, 'postUsageAction') ? undefined : PostUsageActionFromJSON(json['postUsageAction']),
        'consumerId': !exists(json, 'consumerId') ? undefined : json['consumerId'],
        'cdrArrangementId': !exists(json, 'cdrArrangementId') ? undefined : json['cdrArrangementId'],
        'consentId': json['consentId'],
        'directMarketingAllowed': !exists(json, 'directMarketingAllowed') ? undefined : json['directMarketingAllowed'],
        'consumerMobileNumber': !exists(json, 'consumerMobileNumber') ? undefined : json['consumerMobileNumber'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'grantee': !exists(json, 'grantee') ? undefined : ConsentGranteeFromJSON(json['grantee']),
        'serviceProvider': !exists(json, 'serviceProvider') ? undefined : ServiceProviderFromJSON(json['serviceProvider']),
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
    };
}

export function ConsentResponseToJSON(value?: ConsentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'version': value.version,
        'created': value.created,
        'revoked': value.revoked,
        'lastNotificationSentAt': value.lastNotificationSentAt,
        'firstDataCollection': value.firstDataCollection,
        'status': StatusToJSON(value.status),
        'sharingEndDate': value.sharingEndDate,
        'consumerEmail': value.consumerEmail,
        'dataHolderName': value.dataHolderName,
        'dataHolderBrandId': value.dataHolderBrandId,
        'dataHolderLogoUri': value.dataHolderLogoUri,
        'useCase': UseCaseResponseToJSON(value.useCase),
        'postUsageAction': PostUsageActionToJSON(value.postUsageAction),
        'consumerId': value.consumerId,
        'cdrArrangementId': value.cdrArrangementId,
        'consentId': value.consentId,
        'directMarketingAllowed': value.directMarketingAllowed,
        'consumerMobileNumber': value.consumerMobileNumber,
        'externalId': value.externalId,
        'grantee': ConsentGranteeToJSON(value.grantee),
        'serviceProvider': ServiceProviderToJSON(value.serviceProvider),
        'metadata': value.metadata,
    };
}

