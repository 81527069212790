/* tslint:disable */
/* eslint-disable */
/**
 * Adatree REST API for managing Software Products
 * DCR Management APIs allow ADR customers to * enable / disable software products after it is LIVE in ACCC registry and synced back into Adatree ADR platform * provides a way to soft launch software products or take software products offline 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SoftwareProductStatus = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Removed: 'REMOVED'
} as const;
export type SoftwareProductStatus = typeof SoftwareProductStatus[keyof typeof SoftwareProductStatus];


export function SoftwareProductStatusFromJSON(json: any): SoftwareProductStatus {
    return SoftwareProductStatusFromJSONTyped(json, false);
}

export function SoftwareProductStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): SoftwareProductStatus {
    return json as SoftwareProductStatus;
}

export function SoftwareProductStatusToJSON(value?: SoftwareProductStatus | null): any {
    return value as any;
}

