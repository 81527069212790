/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Scope } from './Scope';
import {
    ScopeFromJSON,
    ScopeFromJSONTyped,
    ScopeToJSON,
} from './Scope';

/**
 * 
 * @export
 * @interface ScopePurpose
 */
export interface ScopePurpose {
    /**
     * 
     * @type {Scope}
     * @memberof ScopePurpose
     */
    scopeId?: Scope;
    /**
     * 
     * @type {string}
     * @memberof ScopePurpose
     */
    purpose: string;
}

/**
 * Check if a given object implements the ScopePurpose interface.
 */
export function instanceOfScopePurpose(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "purpose" in value;

    return isInstance;
}

export function ScopePurposeFromJSON(json: any): ScopePurpose {
    return ScopePurposeFromJSONTyped(json, false);
}

export function ScopePurposeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScopePurpose {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scopeId': !exists(json, 'scopeId') ? undefined : ScopeFromJSON(json['scopeId']),
        'purpose': json['purpose'],
    };
}

export function ScopePurposeToJSON(value?: ScopePurpose | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scopeId': ScopeToJSON(value.scopeId),
        'purpose': value.purpose,
    };
}

