import { useAuth } from 'react-oidc-context';
import { useUser } from '../../context/user.context';
import { ConfigurationParameters } from '@adatree/react-api-sdk/dist/generated/data';

export const useApiConfigParams = (addBearer = true) => {
  const auth = useAuth();
  const { apiUrl } = useUser();
  let accessToken = '';

  if (auth.user && auth.user.access_token) {
    if (addBearer) {
      accessToken = 'Bearer ' + auth.user?.access_token;
    } else {
      accessToken = auth.user.access_token;
    }
  }

  const configParams: ConfigurationParameters = {
    basePath: apiUrl,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    accessToken,
  };
  return {
    configParams,
  };
};
