/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GranteeResponse } from './GranteeResponse';
import {
    GranteeResponseFromJSON,
    GranteeResponseFromJSONTyped,
    GranteeResponseToJSON,
} from './GranteeResponse';
import type { LinksPaginated } from './LinksPaginated';
import {
    LinksPaginatedFromJSON,
    LinksPaginatedFromJSONTyped,
    LinksPaginatedToJSON,
} from './LinksPaginated';
import type { MetaPaginated } from './MetaPaginated';
import {
    MetaPaginatedFromJSON,
    MetaPaginatedFromJSONTyped,
    MetaPaginatedToJSON,
} from './MetaPaginated';

/**
 * 
 * @export
 * @interface GranteeListResponse
 */
export interface GranteeListResponse {
    /**
     * 
     * @type {Array<GranteeResponse>}
     * @memberof GranteeListResponse
     */
    data?: Array<GranteeResponse>;
    /**
     * 
     * @type {LinksPaginated}
     * @memberof GranteeListResponse
     */
    links?: LinksPaginated;
    /**
     * 
     * @type {MetaPaginated}
     * @memberof GranteeListResponse
     */
    meta?: MetaPaginated;
}

/**
 * Check if a given object implements the GranteeListResponse interface.
 */
export function instanceOfGranteeListResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GranteeListResponseFromJSON(json: any): GranteeListResponse {
    return GranteeListResponseFromJSONTyped(json, false);
}

export function GranteeListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GranteeListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(GranteeResponseFromJSON)),
        'links': !exists(json, 'links') ? undefined : LinksPaginatedFromJSON(json['links']),
        'meta': !exists(json, 'meta') ? undefined : MetaPaginatedFromJSON(json['meta']),
    };
}

export function GranteeListResponseToJSON(value?: GranteeListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(GranteeResponseToJSON)),
        'links': LinksPaginatedToJSON(value.links),
        'meta': MetaPaginatedToJSON(value.meta),
    };
}

