/* tslint:disable */
/* eslint-disable */
/**
 * Adatree REST API for managing Software Products
 * DCR Management APIs allow ADR customers to * enable / disable software products after it is LIVE in ACCC registry and synced back into Adatree ADR platform * provides a way to soft launch software products or take software products offline 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataHolder } from './DataHolder';
import {
    DataHolderFromJSON,
    DataHolderFromJSONTyped,
    DataHolderToJSON,
} from './DataHolder';
import type { DataHolderRegistration } from './DataHolderRegistration';
import {
    DataHolderRegistrationFromJSON,
    DataHolderRegistrationFromJSONTyped,
    DataHolderRegistrationToJSON,
} from './DataHolderRegistration';
import type { HealthyDcrClient } from './HealthyDcrClient';
import {
    HealthyDcrClientFromJSON,
    HealthyDcrClientFromJSONTyped,
    HealthyDcrClientToJSON,
} from './HealthyDcrClient';
import type { Industry } from './Industry';
import {
    IndustryFromJSON,
    IndustryFromJSONTyped,
    IndustryToJSON,
} from './Industry';
import type { MissingDcrClient } from './MissingDcrClient';
import {
    MissingDcrClientFromJSON,
    MissingDcrClientFromJSONTyped,
    MissingDcrClientToJSON,
} from './MissingDcrClient';
import type { SoftwareProductStatus } from './SoftwareProductStatus';
import {
    SoftwareProductStatusFromJSON,
    SoftwareProductStatusFromJSONTyped,
    SoftwareProductStatusToJSON,
} from './SoftwareProductStatus';
import type { StaleDcrClient } from './StaleDcrClient';
import {
    StaleDcrClientFromJSON,
    StaleDcrClientFromJSONTyped,
    StaleDcrClientToJSON,
} from './StaleDcrClient';

/**
 * software product with registered data holders
 * @export
 * @interface SoftwareProduct
 */
export interface SoftwareProduct {
    /**
     * software product id
     * @type {string}
     * @memberof SoftwareProduct
     */
    id: string;
    /**
     * software product name
     * @type {string}
     * @memberof SoftwareProduct
     */
    name: string;
    /**
     * 
     * @type {SoftwareProductStatus}
     * @memberof SoftwareProduct
     */
    status: SoftwareProductStatus;
    /**
     * flag to control DCR process for a software product
     * @type {boolean}
     * @memberof SoftwareProduct
     */
    enabled: boolean;
    /**
     * list of registered data holders, default to allow all data holders if not configured
     * @type {Array<DataHolder>}
     * @memberof SoftwareProduct
     */
    dataHolders?: Array<DataHolder>;
    /**
     * list of allowed industries, default to banking if not configured
     * @type {Array<Industry>}
     * @memberof SoftwareProduct
     */
    industries: Array<Industry>;
    /**
     * redirect URIs for the software product
     * @type {Array<string>}
     * @memberof SoftwareProduct
     */
    redirectUris?: Array<string>;
    /**
     * software product id
     * @type {string}
     * @memberof SoftwareProduct
     */
    defaultRedirectUri?: string;
    /**
     * software product id
     * @type {string}
     * @memberof SoftwareProduct
     */
    revocationUri?: string;
    /**
     * 
     * @type {string}
     * @memberof SoftwareProduct
     */
    softwareStatementAssertion: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SoftwareProduct
     */
    dataHoldersInConsentService?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SoftwareProduct
     */
    tenantDataHolderWhitelist?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SoftwareProduct
     */
    lastSsaUpdate?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SoftwareProduct
     */
    allowedDataHolders: Array<string>;
    /**
     * 
     * @type {Array<HealthyDcrClient>}
     * @memberof SoftwareProduct
     */
    healthyClients: Array<HealthyDcrClient>;
    /**
     * 
     * @type {Array<MissingDcrClient>}
     * @memberof SoftwareProduct
     */
    missingClients: Array<MissingDcrClient>;
    /**
     * 
     * @type {Array<StaleDcrClient>}
     * @memberof SoftwareProduct
     */
    staleClients: Array<StaleDcrClient>;
    /**
     * 
     * @type {Array<DataHolderRegistration>}
     * @memberof SoftwareProduct
     */
    dataHolderRegistrations?: Array<DataHolderRegistration>;
}

/**
 * Check if a given object implements the SoftwareProduct interface.
 */
export function instanceOfSoftwareProduct(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "enabled" in value;
    isInstance = isInstance && "industries" in value;
    isInstance = isInstance && "softwareStatementAssertion" in value;
    isInstance = isInstance && "allowedDataHolders" in value;
    isInstance = isInstance && "healthyClients" in value;
    isInstance = isInstance && "missingClients" in value;
    isInstance = isInstance && "staleClients" in value;

    return isInstance;
}

export function SoftwareProductFromJSON(json: any): SoftwareProduct {
    return SoftwareProductFromJSONTyped(json, false);
}

export function SoftwareProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): SoftwareProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'status': SoftwareProductStatusFromJSON(json['status']),
        'enabled': json['enabled'],
        'dataHolders': !exists(json, 'dataHolders') ? undefined : ((json['dataHolders'] as Array<any>).map(DataHolderFromJSON)),
        'industries': ((json['industries'] as Array<any>).map(IndustryFromJSON)),
        'redirectUris': !exists(json, 'redirectUris') ? undefined : json['redirectUris'],
        'defaultRedirectUri': !exists(json, 'defaultRedirectUri') ? undefined : json['defaultRedirectUri'],
        'revocationUri': !exists(json, 'revocationUri') ? undefined : json['revocationUri'],
        'softwareStatementAssertion': json['softwareStatementAssertion'],
        'dataHoldersInConsentService': !exists(json, 'dataHoldersInConsentService') ? undefined : json['dataHoldersInConsentService'],
        'tenantDataHolderWhitelist': !exists(json, 'tenantDataHolderWhitelist') ? undefined : json['tenantDataHolderWhitelist'],
        'lastSsaUpdate': !exists(json, 'lastSsaUpdate') ? undefined : json['lastSsaUpdate'],
        'allowedDataHolders': json['allowedDataHolders'],
        'healthyClients': ((json['healthyClients'] as Array<any>).map(HealthyDcrClientFromJSON)),
        'missingClients': ((json['missingClients'] as Array<any>).map(MissingDcrClientFromJSON)),
        'staleClients': ((json['staleClients'] as Array<any>).map(StaleDcrClientFromJSON)),
        'dataHolderRegistrations': !exists(json, 'dataHolderRegistrations') ? undefined : ((json['dataHolderRegistrations'] as Array<any>).map(DataHolderRegistrationFromJSON)),
    };
}

export function SoftwareProductToJSON(value?: SoftwareProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'status': SoftwareProductStatusToJSON(value.status),
        'enabled': value.enabled,
        'dataHolders': value.dataHolders === undefined ? undefined : ((value.dataHolders as Array<any>).map(DataHolderToJSON)),
        'industries': ((value.industries as Array<any>).map(IndustryToJSON)),
        'redirectUris': value.redirectUris,
        'defaultRedirectUri': value.defaultRedirectUri,
        'revocationUri': value.revocationUri,
        'softwareStatementAssertion': value.softwareStatementAssertion,
        'dataHoldersInConsentService': value.dataHoldersInConsentService,
        'tenantDataHolderWhitelist': value.tenantDataHolderWhitelist,
        'lastSsaUpdate': value.lastSsaUpdate,
        'allowedDataHolders': value.allowedDataHolders,
        'healthyClients': ((value.healthyClients as Array<any>).map(HealthyDcrClientToJSON)),
        'missingClients': ((value.missingClients as Array<any>).map(MissingDcrClientToJSON)),
        'staleClients': ((value.staleClients as Array<any>).map(StaleDcrClientToJSON)),
        'dataHolderRegistrations': value.dataHolderRegistrations === undefined ? undefined : ((value.dataHolderRegistrations as Array<any>).map(DataHolderRegistrationToJSON)),
    };
}

