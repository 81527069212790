import React, { ReactElement, useEffect, useState } from 'react';
import { useMgmtDcrGetStatus } from '../hooks/api/mgmt/mgmt-dcr-api.hook';
import { CenterLayout } from '../../components/atoms/center-layout/center-layout.atom';
import { CircularProgress } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { URL_PAGES } from '../constants/url.const';
import { useUser } from '../context/user.context';
import { ErrorSnackbar } from '../../components/atoms/snackbars/error-snackbar.atom';
import { useMgmtGetUseCasesTrigger } from '../hooks/api/mgmt/mgmt-use-case-api.hook';

interface Props {
  children: ReactElement | ReactElement[];
}

export const BootstrapProvider = ({ children }: Props) => {
  const { pathname } = useLocation();
  const { getUseCases } = useMgmtGetUseCasesTrigger();
  const { addFeatureAuthorization, hasUseCase, setHasUseCase } = useUser();
  const { tenant, isLoading, error } = useMgmtDcrGetStatus();
  const [isloadingPreChecks, setIsloadingPreChecks] = useState<boolean>(true);
  const navigate = useNavigate();

  // Temp disable get tenant config
  // const { getConfig } = useMgmtGetTenantConfigTrigger();

  useEffect(() => {
    const callGetUseCases = async () => {
      const useCases = await getUseCases({});
      if (useCases && useCases.length > 0) {
        setHasUseCase(true);
      } else {
        setHasUseCase(false);
      }
    };
    if (!hasUseCase) {
      callGetUseCases();
    }
  }, []);

  useEffect(() => {
    // const callGetConfig = async () => {
    //   const config = await getConfig();
    //   console.log('Tenant config', config);
    // };

    // callGetConfig();

    if (tenant && tenant.features) {
      addFeatureAuthorization(tenant.features);
      if (tenant.features.dataHolderTest) {
        if (pathname === URL_PAGES.HOME.url) {
          navigate(URL_PAGES.DOC_DATA_HOLDER_TESTING.url);
        }
      }

      setIsloadingPreChecks(false);
    }
  }, [tenant]);

  useEffect(() => {
    if (error) {
      setIsloadingPreChecks(false);
    }
  }, [error]);

  return (
    <>
      {(isLoading || isloadingPreChecks) && !error && (
        <CenterLayout>
          <CircularProgress />
        </CenterLayout>
      )}
      {!isLoading && !isloadingPreChecks && (
        <>
          {children}
          {error && (
            <ErrorSnackbar message={'Error loading DCR Status. Unable to get Software Product ID.'} error={error} />
          )}
        </>
      )}
    </>
  );
};
