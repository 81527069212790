import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import { AppRoutes } from './routes/routes';
import { Logger, ThemeProvider, defaultTheme } from '@adatree/atomic-components';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { extendTheme } from './app-logic/theme/extend-theme';
import { HelpProvider } from './app-logic/context/help.context';
import { MessengerProvider } from './app-logic/context/messenger.context';

Bugsnag.start({
  apiKey: '734eac5c08aafa4928e4672636a91c61',
  appVersion: process.env.REACT_APP_VERSION || 'no-version-set',
  plugins: [new BugsnagPluginReact()],
});

BugsnagPerformance.start({ apiKey: '734eac5c08aafa4928e4672636a91c61' });

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
const appTimestamp = process.env.REACT_APP_TIMESTAMP || 'no-timestamp-set';
const appVersion = process.env.REACT_APP_VERSION || 'no-version-set';

Logger.info('App version', appVersion, appTimestamp);

const ErrorView = () => (
  <div>
    <p>Inform users of an error in the component tree.</p>
  </div>
);

const App = () => {
  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorView}>
        {/* @ts-expect-error atomic-components is on a different MUI version and it's ThemeOptions does not support MuiDataGrid */}
        <ThemeProvider theme={defaultTheme} extendTheme={extendTheme}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <MessengerProvider>
              <HelpProvider>
                <AppRoutes />
              </HelpProvider>
            </MessengerProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </>
  );
};

export default App;
