import React, { Suspense } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { Layout } from '../components/organisms/layout/layout-page.organism';
import { PageWrapper } from '../components/molecules/page-wrapper/page-wrapper.molecules';
import { PageWrapperFullWidth } from '../components/molecules/page-wrapper/page-wrapper-full-width.molecules';
import { PrivateRoute } from '../components/atoms/private-route/private-route.atom';
import { LoadingSpinner } from '../components/atoms/loading/loading-spinner.atom';
import { URL_PAGES } from '../app-logic/constants/url.const';

// Public pages
import { AuthCallbackPage } from '../components/pages/auth-callback/auth-callback.organism';
import { LoginPage } from '../components/pages/login/login.organism';
import { LogoutPage } from '../components/pages/logout/logout.organism';

// Lazy load private pages
const VisualDataExplorerPage = React.lazy(
  () => import('../components/pages/visual-data-explorer/visual-data-explorer.page'),
);
const ActivityPage = React.lazy(() => import('../components/pages/activity/activity.organism'));
const ApiDocCdrStatementListPage = React.lazy(() => import('../components/pages/api-docs/cdr-statement-api-doc.page'));
const ApiDocExchPage = React.lazy(() => import('../components/pages/api-docs/exch-api-doc.page'));
const ApiDocInsightsPage = React.lazy(() => import('../components/pages/api-docs/insights-api-doc.page'));
const DocSandboxModelDataHolders = React.lazy(
  () => import('../components/pages/api-docs/sandbox-model-data-holders.page'),
);
const ApiDocCustomSvInsightsPage = React.lazy(
  () => import('../components/pages/api-docs/custom-sv-Insights-api-doc.page'),
);
const DocumentationPage = React.lazy(() => import('../components/pages/documentation/documentation.page'));
const ApiTestPage = React.lazy(() => import('../components/pages/api-test/api-test.page'));

const ConsentDashboardConfigPage = React.lazy(
  () => import('../components/pages/consent-dashboard/consent-dashboard-config-edit.page'),
);
const ConsentPage = React.lazy(() => import('../components/pages/consent/consent.page'));
const ConsentCallbackPopupPage = React.lazy(
  () => import('../components/pages/consent-callback/consent-callback-popup.page'),
);
const ConsentCreatePage = React.lazy(() => import('../components/pages/consent/consent-create.page'));
const ConsentDetailsPage = React.lazy(() => import('../components/pages/consent/consent-details.page'));
const DashboardAuthenticationPage = React.lazy(
  () => import('../components/pages/dashboard-authentication/dashboard-authentication.page'),
);
const DashboardEnvironmentPage = React.lazy(
  () => import('../components/pages/dashboard-environment/dashboard-environment.page'),
);
const DashboardThemePage = React.lazy(() => import('../components/pages/dashboard-theme/dashboard-theme.page'));
const DataHolderStatusPage = React.lazy(() => import('../components/pages/data-holder/data-holder-status.organism'));
const DataHolderTestPage = React.lazy(() => import('../components/pages/data-holder-tests/data-holder-test.page'));
const DataCollectionsPage = React.lazy(() => import('../components/pages/data-collections/data-collections.page'));
const DataHolderSessionsPage = React.lazy(() => import('../components/pages/sessions/sessions.page'));
const DataHolderInteractionsPage = React.lazy(() => import('../components/pages/interactions/interactions.page'));
const DynamicFromSchemaPage = React.lazy(
  () => import('../components/pages/dynamic-form-schema/dynamic-forms-schema.page'),
);
const ExchangePage = React.lazy(() => import('../components/pages/exchange/exchange.page'));
const ExploreDataPage = React.lazy(() => import('../components/pages/explore-data/explore-data.page'));
const ExploreBankingDataPage = React.lazy(
  () => import('../components/pages/explore-data/banking/explore-banking-data.page'),
);
const ExploreBankingDataRealTimePage = React.lazy(
  () => import('../components/pages/explore-data/banking/explore-banking-real-time-data.page'),
);
const ExploreEnergyDataPage = React.lazy(
  () => import('../components/pages/explore-data/energy/explore-energy-data.page'),
);
const TenantSetupPage = React.lazy(() => import('../components/pages/tenant-setup/tenant-setup.page'));
const GettingStartedPage = React.lazy(() => import('../components/pages/getting-started/getting-started.page'));
const GranteeListPage = React.lazy(() => import('../components/pages/grantee/grantee-list.page'));
const MogoPlusPage = React.lazy(() => import('../components/pages/exchange/mogoplus/mogoplus.page'));
const MogoPlusSearchPage = React.lazy(
  () => import('../components/pages/exchange/mogoplus/mogoplus-search-report.page'),
);
const MogoPlusCreatePage = React.lazy(
  () => import('../components/pages/exchange/mogoplus/mogoplus-create-report.page'),
);
const MogoPlusSendPage = React.lazy(() => import('../components/pages/exchange/mogoplus/mogoplus-send-email.page'));
const MaintenancePage = React.lazy(() => import('../components/pages/maintenance/maintenance.page'));
const OnboardingPage = React.lazy(() => import('../components/pages/onboarding/onboarding.page'));
const ProductReferenceDataPage = React.lazy(() => import('../components/pages/product-referece-data/product.organism'));
const ProfilePage = React.lazy(() => import('../components/pages/profile/profile.page'));
const SoftwareProductsPage = React.lazy(() => import('../components/pages/software-products/software-products.page'));
const UseCaseCreatePage = React.lazy(() => import('../components/pages/use-case/use-case-create.page'));
const UseCaseEditPage = React.lazy(() => import('../components/pages/use-case/use-case-edit.page'));
const UseCaseListPage = React.lazy(() => import('../components/pages/use-case/use-case-list.page'));
const UsagePage = React.lazy(() => import('../components/pages/usage/usage.organism'));
const ValidateArrangementPage = React.lazy(
  () => import('../components/pages/validate-arrangement/validate-arrangement.page'),
);
const TestLabPage = React.lazy(() => import('../components/pages/test-lab/test-lab.page'));

export const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path={URL_PAGES.CONSENT_CALLBACK.url}
        element={
          <PrivateRoute>
            <ConsentCallbackPopupPage />
          </PrivateRoute>
        }
      />
      <Route
        element={
          <PrivateRoute>
            <Layout />
          </PrivateRoute>
        }
      >
        <Route
          element={
            <PageWrapper>
              <Suspense fallback={<LoadingSpinner />}>
                <Outlet />
              </Suspense>
            </PageWrapper>
          }
        >
          <Route path={URL_PAGES.API_DOC_CDR_STATEMENT.url} element={<ApiDocCdrStatementListPage />} />
          <Route path={URL_PAGES.API_DOC_EXCH.url} element={<ApiDocExchPage />} />
          <Route path={URL_PAGES.API_DOC_INSIGHTS.url} element={<ApiDocInsightsPage />} />
          <Route path={URL_PAGES.API_DOC_CUSTOM_SV_INSIGHTS.url} element={<ApiDocCustomSvInsightsPage />} />
          <Route path={URL_PAGES.API_TEST.url} element={<ApiTestPage />} />
          <Route path={URL_PAGES.DOCS.url} element={<DocumentationPage />} />
          <Route path={URL_PAGES.DOC_SANDBOX_DATA_HOLDERS.url} element={<DocSandboxModelDataHolders />} />
          <Route path={URL_PAGES.CHARTS.url} element={<ActivityPage />} />
          <Route path={URL_PAGES.CHARTS_2.url} element={<UsagePage />} />
          <Route path={URL_PAGES.CONSENT.url} element={<ConsentPage />} />
          <Route path={URL_PAGES.CONSENT_CREATE.url} element={<ConsentCreatePage />} />
          <Route path={URL_PAGES.CONSENT_DASHBOARD_CONFIG.url} element={<ConsentDashboardConfigPage />} />
          <Route path={URL_PAGES.CONSENT_DETAILS.url} element={<ConsentDetailsPage />} />
          <Route path={URL_PAGES.DASHBOARD_AUTH.url} element={<DashboardAuthenticationPage />} />
          <Route path={URL_PAGES.DASHBOARD_ENV.url} element={<DashboardEnvironmentPage />} />
          <Route path={URL_PAGES.DASHBOARD_THEME.url} element={<DashboardThemePage />} />
          <Route path={URL_PAGES.DATA_COLLECTIONS.url} element={<DataCollectionsPage />} />
          <Route path={URL_PAGES.DATA_HOLDER_SESSIONS.url} element={<DataHolderSessionsPage />} />
          <Route path={URL_PAGES.DATA_HOLDER_INTERACTIONS.url} element={<DataHolderInteractionsPage />} />
          <Route path={URL_PAGES.DEV_RESOURCES.url} element={<p>{URL_PAGES.DEV_RESOURCES.label}</p>} />
          <Route path={URL_PAGES.DOC_DATA_HOLDER_TESTING.url} element={<DataHolderTestPage />} />
          <Route path={URL_PAGES.DYNAMIC_FORMS.url} element={<DynamicFromSchemaPage />} />
          <Route path={URL_PAGES.EXCH_HOME.url} element={<ExchangePage />} />
          <Route path={URL_PAGES.EXPLORE_HOME.url} element={<ExploreDataPage />} />
          <Route path={URL_PAGES.EXPLORE_BANKING.url} element={<ExploreBankingDataPage />} />
          <Route path={URL_PAGES.EXPLORE_BANKING_REAL_TIME.url} element={<ExploreBankingDataRealTimePage />} />
          <Route path={URL_PAGES.EXPLORE_ENERGY.url} element={<ExploreEnergyDataPage />} />
          <Route path={URL_PAGES.GRANTEE_LIST.url} element={<GranteeListPage />} />
          <Route path={URL_PAGES.MOGO_HOME.url} element={<MogoPlusPage />} />
          <Route path={URL_PAGES.MOGO_INSIGHT_RPT_SEARCH.url} element={<MogoPlusSearchPage />} />
          <Route path={URL_PAGES.MOGO_INSIGHT_RPT_CREATE.url} element={<MogoPlusCreatePage />} />
          <Route path={URL_PAGES.MOGO_INSIGHT_RPT_SEND_EMAIL.url} element={<MogoPlusSendPage />} />
          <Route path={URL_PAGES.HOME.url} element={<GettingStartedPage />} />
          <Route path={URL_PAGES.ONBOARDING.url} element={<OnboardingPage />} />
          <Route path={URL_PAGES.PROD_EXPLORER.url} element={<ProductReferenceDataPage />} />
          <Route path={URL_PAGES.PROFILE.url} element={<ProfilePage />} />
          <Route path={URL_PAGES.SOFTWARE_PRODUCTS.url} element={<SoftwareProductsPage />} />
          <Route path={URL_PAGES.TENANT_SETUP.url} element={<TenantSetupPage />} />
          <Route path={URL_PAGES.TEST_LAB.url} element={<TestLabPage />} />
          <Route path={URL_PAGES.DH_STATUS.url} element={<DataHolderStatusPage />} />
          <Route path={URL_PAGES.USE_CASE_CREATE.url} element={<UseCaseCreatePage />} />
          <Route path={URL_PAGES.USE_CASE_EDIT.url} element={<UseCaseEditPage />} />
          <Route path={URL_PAGES.USE_CASE_LIST.url} element={<UseCaseListPage />} />
          <Route path={URL_PAGES.VALIDATE_ARRANGEMENT.url} element={<ValidateArrangementPage />} />
          <Route path={URL_PAGES.ERROR.url} element={<p>{URL_PAGES.ERROR.label}</p>} />
        </Route>
        <Route
          element={
            <PageWrapperFullWidth>
              <Suspense fallback={<LoadingSpinner />}>
                <Outlet />
              </Suspense>
            </PageWrapperFullWidth>
          }
        >
          <Route path={URL_PAGES.VISUAL_DATA_EXPLORER.url} element={<VisualDataExplorerPage />} />
        </Route>
      </Route>
      <Route>
        <Route path={URL_PAGES.AUTH_CALLBACK.url} element={<AuthCallbackPage />} />
        <Route path={URL_PAGES.LOGIN.url} element={<LoginPage />} />
        <Route path={URL_PAGES.LOGOUT.url} element={<LogoutPage />} />
        <Route path={URL_PAGES.MAINTENANCE.url} element={<MaintenancePage />} />
      </Route>
    </Routes>
  );
};
