/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataHolder
 */
export interface DataHolder {
    /**
     * The identifier of the data holder you wish the consumer to authenticate with.
     * @type {string}
     * @memberof DataHolder
     */
    dataHolderBrandId: string;
    /**
     * The brand name of the data holder you wish the consumer to authenticate with.
     * @type {string}
     * @memberof DataHolder
     */
    brandName: string;
    /**
     * The logo URI of the data holder you wish the consumer to authenticate with.
     * @type {string}
     * @memberof DataHolder
     */
    logoUri: string;
    /**
     * 
     * @type {boolean}
     * @memberof DataHolder
     */
    favourite?: boolean;
}

/**
 * Check if a given object implements the DataHolder interface.
 */
export function instanceOfDataHolder(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dataHolderBrandId" in value;
    isInstance = isInstance && "brandName" in value;
    isInstance = isInstance && "logoUri" in value;

    return isInstance;
}

export function DataHolderFromJSON(json: any): DataHolder {
    return DataHolderFromJSONTyped(json, false);
}

export function DataHolderFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataHolder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataHolderBrandId': json['dataHolderBrandId'],
        'brandName': json['brandName'],
        'logoUri': json['logoUri'],
        'favourite': !exists(json, 'favourite') ? undefined : json['favourite'],
    };
}

export function DataHolderToJSON(value?: DataHolder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataHolderBrandId': value.dataHolderBrandId,
        'brandName': value.brandName,
        'logoUri': value.logoUri,
        'favourite': value.favourite,
    };
}

