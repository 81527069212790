/* tslint:disable */
/* eslint-disable */
/**
 * Adatree REST API for managing Software Products
 * DCR Management APIs allow ADR customers to * enable / disable software products after it is LIVE in ACCC registry and synced back into Adatree ADR platform * provides a way to soft launch software products or take software products offline 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Industry } from './Industry';
import {
    IndustryFromJSON,
    IndustryFromJSONTyped,
    IndustryToJSON,
} from './Industry';
import type { MetaBanking } from './MetaBanking';
import {
    MetaBankingFromJSON,
    MetaBankingFromJSONTyped,
    MetaBankingToJSON,
} from './MetaBanking';

/**
 * 
 * @export
 * @interface DataHolder
 */
export interface DataHolder {
    /**
     * The identifier of the data holder you wish the consumer to authenticate with.
     * @type {string}
     * @memberof DataHolder
     */
    dataHolderBrandId: string;
    /**
     * The brand name of the data holder you wish the consumer to authenticate with.
     * @type {string}
     * @memberof DataHolder
     */
    brandName: string;
    /**
     * The logo URI of the data holder you wish the consumer to authenticate with.
     * @type {string}
     * @memberof DataHolder
     */
    logoUri: string;
    /**
     * 
     * @type {string}
     * @memberof DataHolder
     */
    sector?: string;
    /**
     * a list of Authorisation Scopes supported by data holder
     * @type {Array<string>}
     * @memberof DataHolder
     */
    scopesSupported?: Array<string>;
    /**
     * 
     * @type {Array<Industry>}
     * @memberof DataHolder
     */
    industry: Array<Industry>;
    /**
     * 
     * @type {string}
     * @memberof DataHolder
     */
    publicBaseUri: string;
    /**
     * 
     * @type {string}
     * @memberof DataHolder
     */
    registerDataHolderDiscoveryDocument: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DataHolder
     */
    responseTypesSupported: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DataHolder
     */
    status: string;
    /**
     * 
     * @type {MetaBanking}
     * @memberof DataHolder
     */
    meta?: MetaBanking;
}

/**
 * Check if a given object implements the DataHolder interface.
 */
export function instanceOfDataHolder(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dataHolderBrandId" in value;
    isInstance = isInstance && "brandName" in value;
    isInstance = isInstance && "logoUri" in value;
    isInstance = isInstance && "industry" in value;
    isInstance = isInstance && "publicBaseUri" in value;
    isInstance = isInstance && "registerDataHolderDiscoveryDocument" in value;
    isInstance = isInstance && "responseTypesSupported" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function DataHolderFromJSON(json: any): DataHolder {
    return DataHolderFromJSONTyped(json, false);
}

export function DataHolderFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataHolder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataHolderBrandId': json['dataHolderBrandId'],
        'brandName': json['brandName'],
        'logoUri': json['logoUri'],
        'sector': !exists(json, 'sector') ? undefined : json['sector'],
        'scopesSupported': !exists(json, 'scopesSupported') ? undefined : json['scopesSupported'],
        'industry': ((json['industry'] as Array<any>).map(IndustryFromJSON)),
        'publicBaseUri': json['publicBaseUri'],
        'registerDataHolderDiscoveryDocument': json['registerDataHolderDiscoveryDocument'],
        'responseTypesSupported': json['responseTypesSupported'],
        'status': json['status'],
        'meta': !exists(json, 'meta') ? undefined : MetaBankingFromJSON(json['meta']),
    };
}

export function DataHolderToJSON(value?: DataHolder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataHolderBrandId': value.dataHolderBrandId,
        'brandName': value.brandName,
        'logoUri': value.logoUri,
        'sector': value.sector,
        'scopesSupported': value.scopesSupported,
        'industry': ((value.industry as Array<any>).map(IndustryToJSON)),
        'publicBaseUri': value.publicBaseUri,
        'registerDataHolderDiscoveryDocument': value.registerDataHolderDiscoveryDocument,
        'responseTypesSupported': value.responseTypesSupported,
        'status': value.status,
        'meta': MetaBankingToJSON(value.meta),
    };
}

