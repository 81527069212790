import * as React from 'react';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { useMgmtDcrGetStatus } from '../../../app-logic/hooks/api/mgmt/mgmt-dcr-api.hook';
import { useUser } from '../../../app-logic/context/user.context';
import { useEffect } from 'react';
import { Logger } from '@adatree/atomic-components';

export const SoftwareProductSelector = () => {
  const theme = useTheme();

  const { setSoftwareProductId } = useUser();
  const { softwareProducts, isLoading: isLoading, error: error } = useMgmtDcrGetStatus();

  const handleChange = (event: SelectChangeEvent) => {
    Logger.info('handleChange value', event.target.value);
  };

  useEffect(() => {
    if (softwareProducts && softwareProducts.length > 0) {
      setSoftwareProductId(softwareProducts[0].id);
    }
  }, [softwareProducts]);

  return (
    <Box
      sx={{
        mr: 2,
        ml: 0,
        width: '100%',
        minWidth: 140,
        borderRadius: '4px',
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(1),
          width: 'auto',
        },
        '&:hover': {
          backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
      }}
    >
      {!isLoading && !error && softwareProducts && softwareProducts.length > 0 && (
        <FormControl fullWidth>
          <Select
            sx={{
              height: '30px',
              color: 'white',
              '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
              '& .MuiSvgIcon-root': { color: 'white' },
            }}
            labelId="enviorment-selector-label"
            id="enviorment-selector"
            value={softwareProducts[0].id}
            defaultValue={softwareProducts[0].id}
            onChange={handleChange}
          >
            {softwareProducts.map((softwareProduct, index) => {
              return (
                <MenuItem value={softwareProduct.id} key={index}>
                  {softwareProduct.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </Box>
  );
};
