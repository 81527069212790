/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConsentAuthorizationRequest
 */
export interface ConsentAuthorizationRequest {
    /**
     * A state is generated by the consent user-agent (browser) and is used to bind the redirection response from a Data Holder with a user session as per https://openid.net/specs/openid-financial-api-part-1-1_0-final.html#public-client. It is sent in the authentication request, and is then also included redirect from the ADH to the ADR. This allows the ADR client application prevent CSRF attacks. Must be unique per authorization request.
     * @type {string}
     * @memberof ConsentAuthorizationRequest
     */
    state: string;
    /**
     * The URI to redirect to from the data holder when the consumer has finished data holder authentication. Must be from the list of redirect URIs configured at the ACCC register.
     * @type {string}
     * @memberof ConsentAuthorizationRequest
     */
    redirectUri: string;
    /**
     * Date/time of the consent expiry. For new consents, it's a consent expiry. For existing consents, it's the target consent extension date/time.
     * @type {string}
     * @memberof ConsentAuthorizationRequest
     */
    sharingEndDate: string;
}

/**
 * Check if a given object implements the ConsentAuthorizationRequest interface.
 */
export function instanceOfConsentAuthorizationRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "redirectUri" in value;
    isInstance = isInstance && "sharingEndDate" in value;

    return isInstance;
}

export function ConsentAuthorizationRequestFromJSON(json: any): ConsentAuthorizationRequest {
    return ConsentAuthorizationRequestFromJSONTyped(json, false);
}

export function ConsentAuthorizationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsentAuthorizationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'state': json['state'],
        'redirectUri': json['redirectUri'],
        'sharingEndDate': json['sharingEndDate'],
    };
}

export function ConsentAuthorizationRequestToJSON(value?: ConsentAuthorizationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': value.state,
        'redirectUri': value.redirectUri,
        'sharingEndDate': value.sharingEndDate,
    };
}

