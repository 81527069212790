/* tslint:disable */
/* eslint-disable */
/**
 * Adatree REST API for managing Software Products
 * DCR Management APIs allow ADR customers to * enable / disable software products after it is LIVE in ACCC registry and synced back into Adatree ADR platform * provides a way to soft launch software products or take software products offline 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Industry = {
    Banking: 'BANKING',
    Energy: 'ENERGY'
} as const;
export type Industry = typeof Industry[keyof typeof Industry];


export function IndustryFromJSON(json: any): Industry {
    return IndustryFromJSONTyped(json, false);
}

export function IndustryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Industry {
    return json as Industry;
}

export function IndustryToJSON(value?: Industry | null): any {
    return value as any;
}

