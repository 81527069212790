/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccessFrequency } from './AccessFrequency';
import {
    AccessFrequencyFromJSON,
    AccessFrequencyFromJSONTyped,
    AccessFrequencyToJSON,
} from './AccessFrequency';
import type { ConsumerType } from './ConsumerType';
import {
    ConsumerTypeFromJSON,
    ConsumerTypeFromJSONTyped,
    ConsumerTypeToJSON,
} from './ConsumerType';
import type { Industry } from './Industry';
import {
    IndustryFromJSON,
    IndustryFromJSONTyped,
    IndustryToJSON,
} from './Industry';
import type { NotificationType } from './NotificationType';
import {
    NotificationTypeFromJSON,
    NotificationTypeFromJSONTyped,
    NotificationTypeToJSON,
} from './NotificationType';
import type { OutsourcedServiceProvider } from './OutsourcedServiceProvider';
import {
    OutsourcedServiceProviderFromJSON,
    OutsourcedServiceProviderFromJSONTyped,
    OutsourcedServiceProviderToJSON,
} from './OutsourcedServiceProvider';
import type { ScopePurpose } from './ScopePurpose';
import {
    ScopePurposeFromJSON,
    ScopePurposeFromJSONTyped,
    ScopePurposeToJSON,
} from './ScopePurpose';
import type { SharingDuration } from './SharingDuration';
import {
    SharingDurationFromJSON,
    SharingDurationFromJSONTyped,
    SharingDurationToJSON,
} from './SharingDuration';

/**
 * 
 * @export
 * @interface UseCase
 */
export interface UseCase {
    /**
     * 
     * @type {string}
     * @memberof UseCase
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UseCase
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UseCase
     */
    softwareProductId: string;
    /**
     * 
     * @type {string}
     * @memberof UseCase
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UseCase
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UseCase
     */
    priority?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCase
     */
    historicalCollectionPeriodInDays: number;
    /**
     * 
     * @type {NotificationType}
     * @memberof UseCase
     */
    notificationType?: NotificationType;
    /**
     * scopeIds with corresponding purposes for display
     * @type {Array<ScopePurpose>}
     * @memberof UseCase
     */
    scopes: Array<ScopePurpose>;
    /**
     * 
     * @type {AccessFrequency}
     * @memberof UseCase
     */
    accessFrequency: AccessFrequency;
    /**
     * default to ["CUSTOM"] for Ongoing consent; default to ["ONCE_OFF"] for Once_off consent if not supplied
     * @type {Array<SharingDuration>}
     * @memberof UseCase
     */
    sharingDurations?: Array<SharingDuration>;
    /**
     * list of features enabled for use-case
     * @type {Array<string>}
     * @memberof UseCase
     */
    features?: Array<string>;
    /**
     * list of industries this use-case applies to.
     * @type {Array<Industry>}
     * @memberof UseCase
     */
    industries: Array<Industry>;
    /**
     * 
     * @type {ConsumerType}
     * @memberof UseCase
     */
    consumerType: ConsumerType;
    /**
     * list of OSPs used to provide this use case.
     * @type {Array<OutsourcedServiceProvider>}
     * @memberof UseCase
     */
    osps?: Array<OutsourcedServiceProvider>;
    /**
     * Stringified JSON Schema for customData
     * @type {string}
     * @memberof UseCase
     */
    customDataSchema?: string;
}

/**
 * Check if a given object implements the UseCase interface.
 */
export function instanceOfUseCase(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "softwareProductId" in value;
    isInstance = isInstance && "historicalCollectionPeriodInDays" in value;
    isInstance = isInstance && "scopes" in value;
    isInstance = isInstance && "accessFrequency" in value;
    isInstance = isInstance && "industries" in value;
    isInstance = isInstance && "consumerType" in value;

    return isInstance;
}

export function UseCaseFromJSON(json: any): UseCase {
    return UseCaseFromJSONTyped(json, false);
}

export function UseCaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UseCase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'softwareProductId': json['softwareProductId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'historicalCollectionPeriodInDays': json['historicalCollectionPeriodInDays'],
        'notificationType': !exists(json, 'notificationType') ? undefined : NotificationTypeFromJSON(json['notificationType']),
        'scopes': ((json['scopes'] as Array<any>).map(ScopePurposeFromJSON)),
        'accessFrequency': AccessFrequencyFromJSON(json['accessFrequency']),
        'sharingDurations': !exists(json, 'sharingDurations') ? undefined : ((json['sharingDurations'] as Array<any>).map(SharingDurationFromJSON)),
        'features': !exists(json, 'features') ? undefined : json['features'],
        'industries': ((json['industries'] as Array<any>).map(IndustryFromJSON)),
        'consumerType': ConsumerTypeFromJSON(json['consumerType']),
        'osps': !exists(json, 'osps') ? undefined : ((json['osps'] as Array<any>).map(OutsourcedServiceProviderFromJSON)),
        'customDataSchema': !exists(json, 'customDataSchema') ? undefined : json['customDataSchema'],
    };
}

export function UseCaseToJSON(value?: UseCase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'softwareProductId': value.softwareProductId,
        'description': value.description,
        'active': value.active,
        'priority': value.priority,
        'historicalCollectionPeriodInDays': value.historicalCollectionPeriodInDays,
        'notificationType': NotificationTypeToJSON(value.notificationType),
        'scopes': ((value.scopes as Array<any>).map(ScopePurposeToJSON)),
        'accessFrequency': AccessFrequencyToJSON(value.accessFrequency),
        'sharingDurations': value.sharingDurations === undefined ? undefined : ((value.sharingDurations as Array<any>).map(SharingDurationToJSON)),
        'features': value.features,
        'industries': ((value.industries as Array<any>).map(IndustryToJSON)),
        'consumerType': ConsumerTypeToJSON(value.consumerType),
        'osps': value.osps === undefined ? undefined : ((value.osps as Array<any>).map(OutsourcedServiceProviderToJSON)),
        'customDataSchema': value.customDataSchema,
    };
}

