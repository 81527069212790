import { DataHolder, Industry } from '../../../generated/mgmt/consent';
import { DataHolder as DcrDataHolder, instanceOfDataHolder } from '../../../generated/mgmt/dcr';

/**
 * Returns a sorted array of data holders
 *
 * @param dataHolders
 * @returns
 */
export const sort = (dataHolders: DataHolder[]): DataHolder[] => {
  return dataHolders.sort(compare);
};

/**
 * Returns a sorted array of DCR data holders
 *
 * @param dcrDataHolders
 * @returns
 */
export const sortDcr = (dcrDataHolders: DcrDataHolder[]): DcrDataHolder[] => {
  return dcrDataHolders.sort(compare);
};

const compare = (a: DataHolder, b: DataHolder) => {
  if (a.brandName && b.brandName) {
    if (a.brandName < b?.brandName) {
      return -1;
    }
    if (a.brandName > b.brandName) {
      return 1;
    }
  }
  return 0;
};

/**
 * Returns the IDs of data holders where favourite is set to true
 *
 * @param dataHolders
 * @returns
 */
export const getFavouriteDataHolderIds = (dataHolders: DataHolder[]): string[] => {
  const dataHolderIds: string[] = [];

  dataHolders.map((dh) => {
    if (dh.favourite) {
      dataHolderIds.push(dh.dataHolderBrandId);
    }
  });

  return dataHolderIds;
};

type GroupedByIndustry = { banking: DataHolder[]; energy: DataHolder[]; other: DataHolder[] };
/**
 * Returns an object with arrays of data holders grouped by industry
 *
 * @param dataHolders
 * @returns
 */
export const getDataHoldersByIndusty = (dataHolders: DataHolder[] | DcrDataHolder[]): GroupedByIndustry => {
  const bankingDataHolders: DataHolder[] = [];
  const energyDataHolders: DataHolder[] = [];
  const otherDataHolders: DataHolder[] = [];

  if (dataHolders && dataHolders.length > 0) {
    dataHolders.map((dh) => {
      if (instanceOfDataHolder(dh) && 'industry' in dh) {
        if (dh.industry.includes(Industry.Banking)) {
          bankingDataHolders.push(dh);
        } else if (dh.industry.includes(Industry.Energy)) {
          energyDataHolders.push(dh);
        } else {
          otherDataHolders.push(dh);
        }
      } else {
        otherDataHolders.push(dh);
      }
    });
  }

  return { banking: bankingDataHolders, energy: energyDataHolders, other: otherDataHolders };
};

/**
 * Returns the IDs of data holders that are in both sets
 *
 * @param dataHoldersSetA
 * @param dataHoldersSetA
 * @returns
 */
export const getUnionOfDataHolderIds = (dataHoldersSetA: DataHolder[], dataHoldersSetB: DataHolder[]): string[] => {
  const dataHolderIds: string[] = [];

  dataHoldersSetA.forEach((dha) => {
    const found = dataHoldersSetB.find((dhb) => {
      return dha.dataHolderBrandId === dhb.dataHolderBrandId;
    });

    if (found) {
      dataHolderIds.push(found.dataHolderBrandId);
    }
  });

  return dataHolderIds;
};

/**
 * Returns an arrays of hydrated data holders
 *
 * @param dataHolders
 * @returns
 */
export const hydrateDataHolders = (
  dehydratedDataHolders: DataHolder[],
  hydratedDataHolders: DcrDataHolder[],
): DcrDataHolder[] | DataHolder[] => {
  const hydratedArray: DcrDataHolder[] = [];

  dehydratedDataHolders.map((dehydrated) => {
    const found = hydratedDataHolders.find((hydrated) => {
      return hydrated.dataHolderBrandId === dehydrated.dataHolderBrandId;
    });

    if (found) {
      hydratedArray.push(found);
    }
  });

  return hydratedArray.length === dehydratedDataHolders.length ? hydratedArray : dehydratedDataHolders;
};

/**
 * Temp
 *
 * @param dataHolders
 * @param dataHolderId
 * @returns
 */
export const parseDataHolderError = (message: string, dataHolders?: DataHolder[]): string => {
  const re = /(dataHolder:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/gm;

  if (dataHolders && message.match(re)) {
    dataHolders.map((dataHolder) => {
      message = message.replaceAll(`(dataHolder:${dataHolder.dataHolderBrandId})`, `(${dataHolder.brandName})`);
    });
  }

  return message;
};
