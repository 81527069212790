import {
  StatusEndpointForDcrApi,
  Configuration,
  SoftwareProductsApi,
  SoftwareProduct,
  ConfigureSoftwareProductRequest,
  ForceRefreshDataHolderRegistrationRequest,
  DataHoldersApi,
  DataHolder,
  DataHolderRegistration,
  ForceRefreshAllDataHolderRegistrationsForASoftwareProductRequest,
  ResponseError,
} from '../../../../generated/mgmt/dcr';
import { callApi, triggerApi } from '@adatree/react-api-sdk';
import { useApiConfigParams } from '../api-config-params.hook';
import { TenantStatusDcr } from '../../../../generated/mgmt/dcr';

type Response = {
  isLoading: boolean;
  error: ResponseError;
};

type DataHoldesResponse = {
  data: DataHolder[];
  isLoading: boolean;
  error: ResponseError;
};

type TenantStatusDcrResponse = {
  data: TenantStatusDcr;
  isLoading: boolean;
  error: ResponseError;
};

const disableRetry = {
  revalidateOnFocus: false,
};

export const useMgmtDcrGetStatus = () => {
  const apiHookName = 'useMgmtDcrGetStatus';
  const apiMethodName = 'statusDcr';

  const { configParams } = useApiConfigParams();
  const api = new StatusEndpointForDcrApi(new Configuration(configParams));

  const { data, error, isLoading } = callApi(
    api,
    apiHookName,
    apiMethodName,
    {},
    disableRetry,
  ) as TenantStatusDcrResponse;

  return {
    rawResponse: data,
    dcrDataHolders: data?.dataHolders,
    tenant: data?.tenants[0],
    softwareProducts: data?.tenants[0].softwareProducts,
    isLoading,
    error,
  };
};

export const useMgmtDcrGetStatusForDataHolder = () => {
  const apiHookName = 'useMgmtDcrGetStatusForDataHolder';
  const apiMethodName = 'statusDcr';

  const { configParams } = useApiConfigParams();
  const api = new StatusEndpointForDcrApi(new Configuration(configParams));

  const { data, error, isLoading } = callApi(
    api,
    apiHookName,
    apiMethodName,
    {},
    disableRetry,
  ) as TenantStatusDcrResponse;

  const testDataHolder = data?.dataHolders
    .filter(() => data.tenants[0].softwareProducts.length > 0)
    .filter((item) => data.tenants[0].softwareProducts[0].tenantDataHolderWhitelist?.includes(item.dataHolderBrandId));

  return {
    rawResponse: data,
    dcrDataHolders: testDataHolder,
    tenant: data?.tenants[0],
    softwareProducts: data?.tenants[0].softwareProducts,
    isLoading,
    error,
  };
};

export const useMgmtDcrGetDataHolders = () => {
  const apiHookName = 'useMgmtDcrGetDataHolders';
  const apiMethodName = 'getRegisteredDataHolders';

  const { configParams } = useApiConfigParams();
  const api = new DataHoldersApi(new Configuration(configParams));

  const { data, error, isLoading } = callApi(api, apiHookName, apiMethodName) as DataHoldesResponse;

  return {
    data,
    isLoading,
    error,
  };
};

type TriggerResponse = {
  update: (requestParams: ConfigureSoftwareProductRequest) => Promise<SoftwareProduct>;
  response: SoftwareProduct;
  isMutating: boolean;
  error: ResponseError;
};

export const useMgmtDcrUpdateSoftwareProducts = (): TriggerResponse => {
  const apiHookName = 'useMgmtDcrUpdateDataHolders';
  const apiMethodName = 'configureSoftwareProduct';

  const { configParams } = useApiConfigParams();
  const api = new SoftwareProductsApi(new Configuration(configParams));

  api.configureSoftwareProduct;
  const { trigger, data, isMutating, error } = triggerApi(api, apiHookName, apiMethodName);

  return {
    update: trigger,
    response: data,
    isMutating,
    error,
  };
};

export const useMgmtDcrRefreshDataHolderRegistration = (softwareProductId: string, dataHolderBrandId: string) => {
  const apiHookName = 'useMgmtDcrRefreshDataHolderRegistration';
  const apiMethodName = 'forceRefreshDataHolderRegistration';
  const requestParameters: ForceRefreshDataHolderRegistrationRequest = {
    softwareProductId: softwareProductId,
    dataHolderBrandId: dataHolderBrandId,
  };

  const { configParams } = useApiConfigParams();
  const api = new SoftwareProductsApi(new Configuration(configParams));

  const { error, isLoading } = callApi(api, apiHookName, apiMethodName, requestParameters) as Response;

  return {
    isLoading,
    error,
  };
};

type RefreshAllTriggerResponse = {
  refresh: (requestParams: ForceRefreshAllDataHolderRegistrationsForASoftwareProductRequest) => Promise<void>;
  isMutating: boolean;
  error: ResponseError;
};

export const useMgmtDcrRefreshAllDataHolderRegistrations = (): RefreshAllTriggerResponse => {
  const apiHookName = 'useMgmtDcrRefreshAllDataHolderRegistrations';
  const apiMethodName = 'forceRefreshAllDataHolderRegistrationsForASoftwareProduct';

  const { configParams } = useApiConfigParams();
  const api = new SoftwareProductsApi(new Configuration(configParams));

  const { trigger, isMutating, error } = triggerApi(api, apiHookName, apiMethodName);

  return {
    refresh: trigger,
    isMutating,
    error,
  };
};

type RefreshTriggerResponse = {
  refresh: (requestParams: ForceRefreshDataHolderRegistrationRequest) => Promise<DataHolderRegistration>;
  response: DataHolderRegistration;
  isMutating: boolean;
  error: ResponseError;
};

export const useMgmtDcrRefreshDataHolderRegistrations = (): RefreshTriggerResponse => {
  const apiHookName = 'useMgmtDcrRefreshDataHolderRegistrations';
  const apiMethodName = 'forceRefreshDataHolderRegistration';

  const { configParams } = useApiConfigParams();
  const api = new SoftwareProductsApi(new Configuration(configParams));

  const { trigger, data, isMutating, error } = triggerApi(api, apiHookName, apiMethodName);

  return {
    refresh: trigger,
    response: data,
    isMutating,
    error,
  };
};

export const mgmtDcrSequentialRefreshDataHolderRegistrations = (
  requestParameters: ForceRefreshDataHolderRegistrationRequest,
  apiUrl: string,
  accessToken: string,
) => {
  const configParams = {
    basePath: apiUrl,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    accessToken,
  };

  const api = new SoftwareProductsApi(new Configuration(configParams));

  return api.forceRefreshDataHolderRegistration(requestParameters);
};
