import React, { ReactElement, createContext, useContext, useState } from 'react';

interface ContextProps {
  helpContent: ReactElement | ReactElement[] | undefined;
  open: boolean;
  setHelpContent: React.Dispatch<React.SetStateAction<ReactElement | ReactElement[] | undefined>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const HelpContext = createContext<ContextProps | undefined>(undefined);

const useHelp = () => {
  const context = useContext(HelpContext);
  if (!context) {
    throw new Error('useHelp must be used within a HelpContext');
  }
  return context;
};

interface ProviderProps {
  children: ReactElement | ReactElement[];
}

const HelpProvider = ({ children }: ProviderProps) => {
  const [helpContent, setHelpContent] = useState<ReactElement | ReactElement[] | undefined>();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <HelpContext.Provider
        value={{
          helpContent,
          open,
          setHelpContent,
          setOpen,
        }}
      >
        {children}
      </HelpContext.Provider>
    </>
  );
};

export { HelpProvider, useHelp };
