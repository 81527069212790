/* tslint:disable */
/* eslint-disable */
/**
 * Adatree REST API for managing Software Products
 * DCR Management APIs allow ADR customers to * enable / disable software products after it is LIVE in ACCC registry and synced back into Adatree ADR platform * provides a way to soft launch software products or take software products offline 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientCredential
 */
export interface ClientCredential {
    /**
     * 
     * @type {string}
     * @memberof ClientCredential
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof ClientCredential
     */
    clientSecret: string;
    /**
     * 
     * @type {string}
     * @memberof ClientCredential
     */
    clientName: string;
    /**
     * 
     * @type {string}
     * @memberof ClientCredential
     */
    creationDate: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClientCredential
     */
    scopes: Array<string>;
}

/**
 * Check if a given object implements the ClientCredential interface.
 */
export function instanceOfClientCredential(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "clientId" in value;
    isInstance = isInstance && "clientSecret" in value;
    isInstance = isInstance && "clientName" in value;
    isInstance = isInstance && "creationDate" in value;
    isInstance = isInstance && "scopes" in value;

    return isInstance;
}

export function ClientCredentialFromJSON(json: any): ClientCredential {
    return ClientCredentialFromJSONTyped(json, false);
}

export function ClientCredentialFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientCredential {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': json['clientId'],
        'clientSecret': json['clientSecret'],
        'clientName': json['clientName'],
        'creationDate': json['creationDate'],
        'scopes': json['scopes'],
    };
}

export function ClientCredentialToJSON(value?: ClientCredential | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientId': value.clientId,
        'clientSecret': value.clientSecret,
        'clientName': value.clientName,
        'creationDate': value.creationDate,
        'scopes': value.scopes,
    };
}

