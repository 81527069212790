/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScopeResponse
 */
export interface ScopeResponse {
    /**
     * 
     * @type {string}
     * @memberof ScopeResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ScopeResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ScopeResponse
     */
    purpose?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScopeResponse
     */
    claims?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ScopeResponse
     */
    priority?: number;
}

/**
 * Check if a given object implements the ScopeResponse interface.
 */
export function instanceOfScopeResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ScopeResponseFromJSON(json: any): ScopeResponse {
    return ScopeResponseFromJSONTyped(json, false);
}

export function ScopeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScopeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'purpose': !exists(json, 'purpose') ? undefined : json['purpose'],
        'claims': !exists(json, 'claims') ? undefined : json['claims'],
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
    };
}

export function ScopeResponseToJSON(value?: ScopeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'id': value.id,
        'purpose': value.purpose,
        'claims': value.claims,
        'priority': value.priority,
    };
}

