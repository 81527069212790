export const AUTH_ROLES = {
  CDR_READ_WRITE_MOGO_ACCESS: 'CdrReadWriteMogoInsightsAccess',
  DATA_HOLDER_READ_WRITE: 'DataHolderReadWrite',
  NON_CDR_READ_ONLY: 'NonCdrReadOnly',
  STAFF_ADMIN: 'StaffAdmin',
};

export const AUTH_FEATURES = {
  ALLOW_CONCURRENT_CONSENTS: 'allowConcurrentConsents',
  DATA_HOLDER_TESTING: 'dataHolderTest',
  GRANTEE: 'grantee',
  USE_ADR_BRANDING: 'useAdrBranding',
};

export const AUTH_PERMISSIONS = {
  INSIGHTS_SV: 'InsightsSVAccountVerification',
};

export const AUTH_ROIDS = {
  MOGO_ROID: 'eb7e3a63-27b3-4995-b9b1-d25318628cb5',
};

// Create, Update, Delete
export const CUD_ROLES = [AUTH_ROLES.STAFF_ADMIN, AUTH_ROLES.DATA_HOLDER_READ_WRITE];

// Admin
export const ADMIN_ROLES = [AUTH_ROLES.STAFF_ADMIN];
export const DATA_HOLDER_TESTER_ROLES = [AUTH_ROLES.DATA_HOLDER_READ_WRITE];
