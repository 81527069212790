/* tslint:disable */
/* eslint-disable */
/**
 * Adatree REST API for managing Software Products
 * DCR Management APIs allow ADR customers to * enable / disable software products after it is LIVE in ACCC registry and synced back into Adatree ADR platform * provides a way to soft launch software products or take software products offline 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClientCredential } from './ClientCredential';
import {
    ClientCredentialFromJSON,
    ClientCredentialFromJSONTyped,
    ClientCredentialToJSON,
} from './ClientCredential';
import type { SoftwareProduct } from './SoftwareProduct';
import {
    SoftwareProductFromJSON,
    SoftwareProductFromJSONTyped,
    SoftwareProductToJSON,
} from './SoftwareProduct';
import type { TenantFeatures } from './TenantFeatures';
import {
    TenantFeaturesFromJSON,
    TenantFeaturesFromJSONTyped,
    TenantFeaturesToJSON,
} from './TenantFeatures';

/**
 * 
 * @export
 * @interface Tenant
 */
export interface Tenant {
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    baseApiDomain: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    consentConsentDashboardUrl: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    accessTokenEndpoint: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    clientSecret?: string;
    /**
     * 
     * @type {Array<ClientCredential>}
     * @memberof Tenant
     */
    clientCredentials: Array<ClientCredential>;
    /**
     * 
     * @type {Array<SoftwareProduct>}
     * @memberof Tenant
     */
    softwareProducts: Array<SoftwareProduct>;
    /**
     * 
     * @type {TenantFeatures}
     * @memberof Tenant
     */
    features?: TenantFeatures;
}

/**
 * Check if a given object implements the Tenant interface.
 */
export function instanceOfTenant(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "baseApiDomain" in value;
    isInstance = isInstance && "consentConsentDashboardUrl" in value;
    isInstance = isInstance && "accessTokenEndpoint" in value;
    isInstance = isInstance && "clientCredentials" in value;
    isInstance = isInstance && "softwareProducts" in value;

    return isInstance;
}

export function TenantFromJSON(json: any): Tenant {
    return TenantFromJSONTyped(json, false);
}

export function TenantFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tenant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'baseApiDomain': json['baseApiDomain'],
        'consentConsentDashboardUrl': json['consentConsentDashboardUrl'],
        'accessTokenEndpoint': json['accessTokenEndpoint'],
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'clientSecret': !exists(json, 'clientSecret') ? undefined : json['clientSecret'],
        'clientCredentials': ((json['clientCredentials'] as Array<any>).map(ClientCredentialFromJSON)),
        'softwareProducts': ((json['softwareProducts'] as Array<any>).map(SoftwareProductFromJSON)),
        'features': !exists(json, 'features') ? undefined : TenantFeaturesFromJSON(json['features']),
    };
}

export function TenantToJSON(value?: Tenant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'baseApiDomain': value.baseApiDomain,
        'consentConsentDashboardUrl': value.consentConsentDashboardUrl,
        'accessTokenEndpoint': value.accessTokenEndpoint,
        'clientId': value.clientId,
        'clientSecret': value.clientSecret,
        'clientCredentials': ((value.clientCredentials as Array<any>).map(ClientCredentialToJSON)),
        'softwareProducts': ((value.softwareProducts as Array<any>).map(SoftwareProductToJSON)),
        'features': TenantFeaturesToJSON(value.features),
    };
}

