import * as React from 'react';
import { createContext, ReactElement, useContext, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { AUTH_ROLES, AUTH_PERMISSIONS } from '../constants/authorization.const';
import { Logger } from '@adatree/atomic-components';
import { TenantFeatures } from '../../generated/mgmt/dcr';
import { DataHolder } from '../../generated/mgmt/consent';
import jwtDecode from 'jwt-decode';

interface JwtPayload {
  apiDomain: string;
  'mgmt-roles': string;
  'mgmt-permissions': string[];
  roid: string;
  ts: Tenant[];
  sub: string;
}

interface Tenant {
  n: string;
  r: string;
}

interface ContextProps {
  resourceOwnerId: string;
  accessTokenSub: string;
  apiUrl: string;
  tenants: Tenant[];
  tenantName: string;
  authorization: string[];
  dataHolders: DataHolder[];
  hasUseCase?: boolean;
  mimicCustomer: string[];
  softwareProductId?: string;
  addAuthorization: (auth: string) => void;
  addFeatureAuthorization: (featureAuths: TenantFeatures) => void;
  setDataHolders: React.Dispatch<React.SetStateAction<DataHolder[]>>;
  setHasUseCase: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setMimicCustomer: React.Dispatch<React.SetStateAction<string[]>>;
  setSoftwareProductId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const sortTenant = (a: Tenant, b: Tenant) => {
  if (a.n.toLocaleLowerCase() < b.n.toLocaleLowerCase()) {
    return -1;
  }
  if (a.n.toLocaleLowerCase() > b.n.toLocaleLowerCase()) {
    return 1;
  }
  return 0;
};

const UserContext = createContext<ContextProps | undefined>(undefined);

const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserContext');
  }
  return context;
};

interface ProviderProps {
  children: ReactElement | ReactElement[];
}

const UserProvider = ({ children }: ProviderProps) => {
  const auth = useAuth();
  const user = auth.user;

  const [accessTokenSub, setAccessTokenSub] = useState<string>();
  const [apiUrl, setApi] = useState<string>();
  const [resourceOwnerId, setResourceOwnerId] = useState<string>();
  const [tenants, setTenants] = useState<Tenant[]>();
  const [tenantName, setTenantName] = useState<string>();
  const [softwareProductId, setSoftwareProductId] = useState<string>();
  const [authorization, setAuthorization] = useState<string[]>([]);
  const [orgAuthorization, setOrgAuthorization] = useState<string[]>([]);
  const [dataHolders, setDataHolders] = useState<DataHolder[]>([]);
  const [mimicCustomer, setMimicCustomer] = useState<string[]>([]);
  const [hasUseCase, setHasUseCase] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (user && user.access_token) {
      try {
        const jwtPayload = jwtDecode<JwtPayload>(user.access_token);
        if (jwtPayload) {
          const role = jwtPayload['mgmt-roles'];
          const permissions = jwtPayload['mgmt-permissions'];

          if (role) {
            if (Object.values(AUTH_ROLES).includes(role)) {
              if (authorization.indexOf(role) === -1) {
                addAuthorization(role);
              }
            } else {
              Logger.warn(`${jwtPayload['mgmt-roles']} is not a recognised role`);
            }
          } else {
            throw new Error('JWT is valid but does not contain a mgmt role');
          }

          if (permissions) {
            permissions.map((permission) => {
              if (Object.values(AUTH_PERMISSIONS).includes(permission)) {
                if (authorization.indexOf(permission) === -1) {
                  addAuthorization(permission);
                }
              } else {
                Logger.warn(`${permission} is not a recognised permission`);
              }
            });
          }

          if (jwtPayload.apiDomain) {
            setApi(`https://${jwtPayload.apiDomain}`);
          } else {
            throw new Error('JWT is valid but does not contain API domain');
          }

          if (jwtPayload.ts && jwtPayload.ts.length > 0) {
            setTenants(jwtPayload.ts.sort(sortTenant));
          } else {
            throw new Error('JWT is valid but does not contain tenants list');
          }

          if (jwtPayload.roid) {
            setResourceOwnerId(jwtPayload.roid);
          } else {
            throw new Error('JWT is valid but does not contain roid');
          }

          if (jwtPayload.sub) {
            setAccessTokenSub(jwtPayload.sub);
          } else {
            throw new Error('JWT is valid but does not contain sub');
          }

          if (jwtPayload.roid && jwtPayload.ts) {
            const tenant = jwtPayload.ts.find((tenant) => {
              return tenant.r === jwtPayload.roid;
            });
            if (tenant) {
              setTenantName(tenant.n);
            }
          } else {
            throw new Error('JWT is invalid cannot set tenant name');
          }
        }
      } catch (error: any) {
        if (error && error?.message) {
          Logger.error(error?.message);
        } else {
          Logger.error('Invalid access token, could not parse JWT');
          Logger.error(error);
        }
      }
    }
  }, [user]);

  useEffect(() => {
    if (mimicCustomer && mimicCustomer.length === 0) {
      if (orgAuthorization.length > 0) {
        setAuthorization([...orgAuthorization]);
      }
    } else if (mimicCustomer && mimicCustomer.length > 0) {
      if (orgAuthorization.length === 0) {
        setOrgAuthorization([...authorization]);
      }
      setAuthorization([...mimicCustomer]);
    }
  }, [mimicCustomer]);

  const addAuthorization = (auth: string) => {
    if (!authorization.includes(auth)) {
      authorization.push(auth);
      setAuthorization([...authorization]);
    }
  };

  const addFeatureAuthorization = (featureAuths: TenantFeatures) => {
    Object.keys(featureAuths).map((key) => {
      if (featureAuths[key as keyof TenantFeatures] === true) {
        if (!authorization.includes(key)) {
          authorization.push(key);
        }
      }

      setAuthorization([...authorization]);
    });
  };

  return (
    <>
      {apiUrl && tenants && resourceOwnerId && tenantName && accessTokenSub && (
        <UserContext.Provider
          value={{
            accessTokenSub,
            apiUrl,
            tenants,
            tenantName,
            resourceOwnerId,
            authorization,
            dataHolders,
            hasUseCase,
            mimicCustomer,
            softwareProductId,
            addAuthorization,
            addFeatureAuthorization,
            setDataHolders,
            setHasUseCase,
            setMimicCustomer,
            setSoftwareProductId,
          }}
        >
          {children}
        </UserContext.Provider>
      )}
    </>
  );
};

export { UserProvider, useUser };
