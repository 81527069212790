/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MetaPaginated } from './MetaPaginated';
import {
    MetaPaginatedFromJSON,
    MetaPaginatedFromJSONTyped,
    MetaPaginatedToJSON,
} from './MetaPaginated';
import type { SessionDataSchema } from './SessionDataSchema';
import {
    SessionDataSchemaFromJSON,
    SessionDataSchemaFromJSONTyped,
    SessionDataSchemaToJSON,
} from './SessionDataSchema';

/**
 * 
 * @export
 * @interface SessionDataSchemaListResponse
 */
export interface SessionDataSchemaListResponse {
    /**
     * 
     * @type {Array<SessionDataSchema>}
     * @memberof SessionDataSchemaListResponse
     */
    schemas?: Array<SessionDataSchema>;
    /**
     * 
     * @type {MetaPaginated}
     * @memberof SessionDataSchemaListResponse
     */
    meta?: MetaPaginated;
}

/**
 * Check if a given object implements the SessionDataSchemaListResponse interface.
 */
export function instanceOfSessionDataSchemaListResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SessionDataSchemaListResponseFromJSON(json: any): SessionDataSchemaListResponse {
    return SessionDataSchemaListResponseFromJSONTyped(json, false);
}

export function SessionDataSchemaListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionDataSchemaListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'schemas': !exists(json, 'schemas') ? undefined : ((json['schemas'] as Array<any>).map(SessionDataSchemaFromJSON)),
        'meta': !exists(json, 'meta') ? undefined : MetaPaginatedFromJSON(json['meta']),
    };
}

export function SessionDataSchemaListResponseToJSON(value?: SessionDataSchemaListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'schemas': value.schemas === undefined ? undefined : ((value.schemas as Array<any>).map(SessionDataSchemaToJSON)),
        'meta': MetaPaginatedToJSON(value.meta),
    };
}

