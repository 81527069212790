/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConsumerType } from './ConsumerType';
import {
    ConsumerTypeFromJSON,
    ConsumerTypeFromJSONTyped,
    ConsumerTypeToJSON,
} from './ConsumerType';
import type { Industry } from './Industry';
import {
    IndustryFromJSON,
    IndustryFromJSONTyped,
    IndustryToJSON,
} from './Industry';
import type { OutsourcedServiceProvider } from './OutsourcedServiceProvider';
import {
    OutsourcedServiceProviderFromJSON,
    OutsourcedServiceProviderFromJSONTyped,
    OutsourcedServiceProviderToJSON,
} from './OutsourcedServiceProvider';

/**
 * amendable parts of the use case definition
 * @export
 * @interface UpdateUseCase
 */
export interface UpdateUseCase {
    /**
     * a list of data holder ids
     * @type {Array<string>}
     * @memberof UpdateUseCase
     */
    dataHolderIds?: Array<string>;
    /**
     * a list of favourite data holder ids
     * @type {Array<string>}
     * @memberof UpdateUseCase
     */
    favouriteDataHolderIds?: Array<string>;
    /**
     * list of features enabled for use-case
     * @type {Array<string>}
     * @memberof UpdateUseCase
     */
    features?: Array<string>;
    /**
     * list of industries this use-case applies to.
     * @type {Array<Industry>}
     * @memberof UpdateUseCase
     */
    industries?: Array<Industry>;
    /**
     * 
     * @type {ConsumerType}
     * @memberof UpdateUseCase
     */
    consumerType?: ConsumerType;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUseCase
     */
    active?: boolean;
    /**
     * list of OSPs used to provide this use case.
     * @type {Array<OutsourcedServiceProvider>}
     * @memberof UpdateUseCase
     */
    osps?: Array<OutsourcedServiceProvider>;
    /**
     * Stringified JSON Schema for customData
     * @type {string}
     * @memberof UpdateUseCase
     */
    customDataSchema?: string;
}

/**
 * Check if a given object implements the UpdateUseCase interface.
 */
export function instanceOfUpdateUseCase(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateUseCaseFromJSON(json: any): UpdateUseCase {
    return UpdateUseCaseFromJSONTyped(json, false);
}

export function UpdateUseCaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUseCase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataHolderIds': !exists(json, 'dataHolderIds') ? undefined : json['dataHolderIds'],
        'favouriteDataHolderIds': !exists(json, 'favouriteDataHolderIds') ? undefined : json['favouriteDataHolderIds'],
        'features': !exists(json, 'features') ? undefined : json['features'],
        'industries': !exists(json, 'industries') ? undefined : ((json['industries'] as Array<any>).map(IndustryFromJSON)),
        'consumerType': !exists(json, 'consumerType') ? undefined : ConsumerTypeFromJSON(json['consumerType']),
        'active': !exists(json, 'active') ? undefined : json['active'],
        'osps': !exists(json, 'osps') ? undefined : ((json['osps'] as Array<any>).map(OutsourcedServiceProviderFromJSON)),
        'customDataSchema': !exists(json, 'customDataSchema') ? undefined : json['customDataSchema'],
    };
}

export function UpdateUseCaseToJSON(value?: UpdateUseCase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataHolderIds': value.dataHolderIds,
        'favouriteDataHolderIds': value.favouriteDataHolderIds,
        'features': value.features,
        'industries': value.industries === undefined ? undefined : ((value.industries as Array<any>).map(IndustryToJSON)),
        'consumerType': ConsumerTypeToJSON(value.consumerType),
        'active': value.active,
        'osps': value.osps === undefined ? undefined : ((value.osps as Array<any>).map(OutsourcedServiceProviderToJSON)),
        'customDataSchema': value.customDataSchema,
    };
}

