/* tslint:disable */
/* eslint-disable */
/**
 * Adatree REST API for managing Software Products
 * DCR Management APIs allow ADR customers to * enable / disable software products after it is LIVE in ACCC registry and synced back into Adatree ADR platform * provides a way to soft launch software products or take software products offline 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Provides DCR information to dependant services
 * @export
 * @interface DataHolderRegistration
 */
export interface DataHolderRegistration {
    /**
     * 
     * @type {string}
     * @memberof DataHolderRegistration
     */
    softwareProductId: string;
    /**
     * 
     * @type {string}
     * @memberof DataHolderRegistration
     */
    softwareProductName: string;
    /**
     * 
     * @type {string}
     * @memberof DataHolderRegistration
     */
    registrationId: string;
    /**
     * 
     * @type {string}
     * @memberof DataHolderRegistration
     */
    dataHolderId: string;
    /**
     * 
     * @type {string}
     * @memberof DataHolderRegistration
     */
    dataHolderBrandName: string;
    /**
     * 
     * @type {string}
     * @memberof DataHolderRegistration
     */
    clientId: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof DataHolderRegistration
     */
    redirectUris: Set<string>;
    /**
     * 
     * @type {string}
     * @memberof DataHolderRegistration
     */
    idTokenSignedResponseAlg: string;
    /**
     * 
     * @type {string}
     * @memberof DataHolderRegistration
     */
    idTokenEncryptedResponseAlg: string;
    /**
     * 
     * @type {string}
     * @memberof DataHolderRegistration
     */
    idTokenEncryptedResponseEnc: string;
    /**
     * 
     * @type {string}
     * @memberof DataHolderRegistration
     */
    requestObjectSigningAlg: string;
    /**
     * 
     * @type {string}
     * @memberof DataHolderRegistration
     */
    tokenEndpointAuthSigningAlg: string;
    /**
     * list of registered response types
     * @type {Array<string>}
     * @memberof DataHolderRegistration
     */
    responseTypes: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DataHolderRegistration
     */
    created: string;
    /**
     * 
     * @type {string}
     * @memberof DataHolderRegistration
     */
    modified: string;
    /**
     * 
     * @type {string}
     * @memberof DataHolderRegistration
     */
    clientIdIssuedAt: string;
}

/**
 * Check if a given object implements the DataHolderRegistration interface.
 */
export function instanceOfDataHolderRegistration(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "softwareProductId" in value;
    isInstance = isInstance && "softwareProductName" in value;
    isInstance = isInstance && "registrationId" in value;
    isInstance = isInstance && "dataHolderId" in value;
    isInstance = isInstance && "dataHolderBrandName" in value;
    isInstance = isInstance && "clientId" in value;
    isInstance = isInstance && "redirectUris" in value;
    isInstance = isInstance && "idTokenSignedResponseAlg" in value;
    isInstance = isInstance && "idTokenEncryptedResponseAlg" in value;
    isInstance = isInstance && "idTokenEncryptedResponseEnc" in value;
    isInstance = isInstance && "requestObjectSigningAlg" in value;
    isInstance = isInstance && "tokenEndpointAuthSigningAlg" in value;
    isInstance = isInstance && "responseTypes" in value;
    isInstance = isInstance && "created" in value;
    isInstance = isInstance && "modified" in value;
    isInstance = isInstance && "clientIdIssuedAt" in value;

    return isInstance;
}

export function DataHolderRegistrationFromJSON(json: any): DataHolderRegistration {
    return DataHolderRegistrationFromJSONTyped(json, false);
}

export function DataHolderRegistrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataHolderRegistration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'softwareProductId': json['softwareProductId'],
        'softwareProductName': json['softwareProductName'],
        'registrationId': json['registrationId'],
        'dataHolderId': json['dataHolderId'],
        'dataHolderBrandName': json['dataHolderBrandName'],
        'clientId': json['clientId'],
        'redirectUris': json['redirectUris'],
        'idTokenSignedResponseAlg': json['idTokenSignedResponseAlg'],
        'idTokenEncryptedResponseAlg': json['idTokenEncryptedResponseAlg'],
        'idTokenEncryptedResponseEnc': json['idTokenEncryptedResponseEnc'],
        'requestObjectSigningAlg': json['requestObjectSigningAlg'],
        'tokenEndpointAuthSigningAlg': json['tokenEndpointAuthSigningAlg'],
        'responseTypes': json['responseTypes'],
        'created': json['created'],
        'modified': json['modified'],
        'clientIdIssuedAt': json['clientIdIssuedAt'],
    };
}

export function DataHolderRegistrationToJSON(value?: DataHolderRegistration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'softwareProductId': value.softwareProductId,
        'softwareProductName': value.softwareProductName,
        'registrationId': value.registrationId,
        'dataHolderId': value.dataHolderId,
        'dataHolderBrandName': value.dataHolderBrandName,
        'clientId': value.clientId,
        'redirectUris': Array.from(value.redirectUris as Set<any>),
        'idTokenSignedResponseAlg': value.idTokenSignedResponseAlg,
        'idTokenEncryptedResponseAlg': value.idTokenEncryptedResponseAlg,
        'idTokenEncryptedResponseEnc': value.idTokenEncryptedResponseEnc,
        'requestObjectSigningAlg': value.requestObjectSigningAlg,
        'tokenEndpointAuthSigningAlg': value.tokenEndpointAuthSigningAlg,
        'responseTypes': value.responseTypes,
        'created': value.created,
        'modified': value.modified,
        'clientIdIssuedAt': value.clientIdIssuedAt,
    };
}

