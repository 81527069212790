/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConsentResponse } from './ConsentResponse';
import {
    ConsentResponseFromJSON,
    ConsentResponseFromJSONTyped,
    ConsentResponseToJSON,
} from './ConsentResponse';
import type { LinksPaginated } from './LinksPaginated';
import {
    LinksPaginatedFromJSON,
    LinksPaginatedFromJSONTyped,
    LinksPaginatedToJSON,
} from './LinksPaginated';
import type { MetaPaginated } from './MetaPaginated';
import {
    MetaPaginatedFromJSON,
    MetaPaginatedFromJSONTyped,
    MetaPaginatedToJSON,
} from './MetaPaginated';

/**
 * 
 * @export
 * @interface ConsentsList
 */
export interface ConsentsList {
    /**
     * 
     * @type {Array<ConsentResponse>}
     * @memberof ConsentsList
     */
    consents: Array<ConsentResponse>;
    /**
     * 
     * @type {LinksPaginated}
     * @memberof ConsentsList
     */
    links: LinksPaginated;
    /**
     * 
     * @type {MetaPaginated}
     * @memberof ConsentsList
     */
    meta: MetaPaginated;
}

/**
 * Check if a given object implements the ConsentsList interface.
 */
export function instanceOfConsentsList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "consents" in value;
    isInstance = isInstance && "links" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function ConsentsListFromJSON(json: any): ConsentsList {
    return ConsentsListFromJSONTyped(json, false);
}

export function ConsentsListFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsentsList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'consents': ((json['consents'] as Array<any>).map(ConsentResponseFromJSON)),
        'links': LinksPaginatedFromJSON(json['links']),
        'meta': MetaPaginatedFromJSON(json['meta']),
    };
}

export function ConsentsListToJSON(value?: ConsentsList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'consents': ((value.consents as Array<any>).map(ConsentResponseToJSON)),
        'links': LinksPaginatedToJSON(value.links),
        'meta': MetaPaginatedToJSON(value.meta),
    };
}

