/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PostUsageAction } from './PostUsageAction';
import {
    PostUsageActionFromJSON,
    PostUsageActionFromJSONTyped,
    PostUsageActionToJSON,
} from './PostUsageAction';

/**
 * Updates a consent. A consumer can update PostUsageAction,directMarketingAllowed and sharingEndDate and a machine user can update external ID. Errors will be thrown if these rules are not followed
 * @export
 * @interface UpdateConsent
 */
export interface UpdateConsent {
    /**
     * 
     * @type {PostUsageAction}
     * @memberof UpdateConsent
     */
    postUsageAction?: PostUsageAction;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConsent
     */
    directMarketingAllowed?: boolean;
    /**
     * new sharing end date, will be rounded to the end of specified date and validated against sharing duration options defined in chosen Use Case
     * @type {string}
     * @memberof UpdateConsent
     */
    sharingEndDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateConsent
     */
    externalId?: string;
}

/**
 * Check if a given object implements the UpdateConsent interface.
 */
export function instanceOfUpdateConsent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateConsentFromJSON(json: any): UpdateConsent {
    return UpdateConsentFromJSONTyped(json, false);
}

export function UpdateConsentFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateConsent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'postUsageAction': !exists(json, 'postUsageAction') ? undefined : PostUsageActionFromJSON(json['postUsageAction']),
        'directMarketingAllowed': !exists(json, 'directMarketingAllowed') ? undefined : json['directMarketingAllowed'],
        'sharingEndDate': !exists(json, 'sharingEndDate') ? undefined : json['sharingEndDate'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
    };
}

export function UpdateConsentToJSON(value?: UpdateConsent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'postUsageAction': PostUsageActionToJSON(value.postUsageAction),
        'directMarketingAllowed': value.directMarketingAllowed,
        'sharingEndDate': value.sharingEndDate,
        'externalId': value.externalId,
    };
}

