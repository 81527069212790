/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Authorization link for a Consent
 * @export
 * @interface ConsentAuthorization
 */
export interface ConsentAuthorization {
    /**
     * indicates the outcome of the authorization request
     * @type {boolean}
     * @memberof ConsentAuthorization
     */
    success: boolean;
    /**
     * An Adatree provided message of what went wrong
     * @type {string}
     * @memberof ConsentAuthorization
     */
    adatreeErrorMessage?: string;
    /**
     * An indication of the error returned by the data holder
     * @type {string}
     * @memberof ConsentAuthorization
     */
    dataHolderErrorMessage?: string;
    /**
     * DateTime of expiry of the authorization URI.
     * @type {string}
     * @memberof ConsentAuthorization
     */
    expiry?: string;
    /**
     * uri to redirect the consumer to
     * @type {string}
     * @memberof ConsentAuthorization
     */
    uri?: string;
}

/**
 * Check if a given object implements the ConsentAuthorization interface.
 */
export function instanceOfConsentAuthorization(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "success" in value;

    return isInstance;
}

export function ConsentAuthorizationFromJSON(json: any): ConsentAuthorization {
    return ConsentAuthorizationFromJSONTyped(json, false);
}

export function ConsentAuthorizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsentAuthorization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
        'adatreeErrorMessage': !exists(json, 'adatreeErrorMessage') ? undefined : json['adatreeErrorMessage'],
        'dataHolderErrorMessage': !exists(json, 'dataHolderErrorMessage') ? undefined : json['dataHolderErrorMessage'],
        'expiry': !exists(json, 'expiry') ? undefined : json['expiry'],
        'uri': !exists(json, 'uri') ? undefined : json['uri'],
    };
}

export function ConsentAuthorizationToJSON(value?: ConsentAuthorization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'adatreeErrorMessage': value.adatreeErrorMessage,
        'dataHolderErrorMessage': value.dataHolderErrorMessage,
        'expiry': value.expiry,
        'uri': value.uri,
    };
}

