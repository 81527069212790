/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Stringified JSON Schema for sessionData
 * @export
 * @interface SessionDataSchema
 */
export interface SessionDataSchema {
    /**
     * The identifier of the session data schema
     * @type {string}
     * @memberof SessionDataSchema
     */
    schemaId?: string;
    /**
     * The name of the session data schema
     * @type {string}
     * @memberof SessionDataSchema
     */
    name?: string;
    /**
     * The description of the session data schema
     * @type {string}
     * @memberof SessionDataSchema
     */
    description?: string;
    /**
     * A stringified JSON Schema
     * @type {string}
     * @memberof SessionDataSchema
     */
    jsonSchema?: string;
    /**
     * If true then this schema is to be used in a scenario where a customer does not want to deal with the complexity of managing or choosing from multiple schemas.
     * @type {boolean}
     * @memberof SessionDataSchema
     */
    isDefault?: boolean;
}

/**
 * Check if a given object implements the SessionDataSchema interface.
 */
export function instanceOfSessionDataSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SessionDataSchemaFromJSON(json: any): SessionDataSchema {
    return SessionDataSchemaFromJSONTyped(json, false);
}

export function SessionDataSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionDataSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'schemaId': !exists(json, 'schemaId') ? undefined : json['schemaId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'jsonSchema': !exists(json, 'jsonSchema') ? undefined : json['jsonSchema'],
        'isDefault': !exists(json, 'isDefault') ? undefined : json['isDefault'],
    };
}

export function SessionDataSchemaToJSON(value?: SessionDataSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'schemaId': value.schemaId,
        'name': value.name,
        'description': value.description,
        'jsonSchema': value.jsonSchema,
        'isDefault': value.isDefault,
    };
}

