/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PasswordlessLoginLinkRequest,
  ResponseErrorList,
  SessionDataSchema,
  SessionDataSchemaListResponse,
} from '../models';
import {
    PasswordlessLoginLinkRequestFromJSON,
    PasswordlessLoginLinkRequestToJSON,
    ResponseErrorListFromJSON,
    ResponseErrorListToJSON,
    SessionDataSchemaFromJSON,
    SessionDataSchemaToJSON,
    SessionDataSchemaListResponseFromJSON,
    SessionDataSchemaListResponseToJSON,
} from '../models';

export interface PasswordlessLoginPostRequest {
    passwordlessLoginLinkRequest?: PasswordlessLoginLinkRequest;
}

export interface PasswordlessLoginSchemasGetRequest {
    page?: number;
    pageSize?: number;
}

export interface PasswordlessLoginSchemasSchemaIdDeleteRequest {
    schemaId: string;
}

export interface PasswordlessLoginSchemasSchemaIdPutRequest {
    schemaId: string;
    sessionDataSchema?: SessionDataSchema;
}

/**
 * 
 */
export class IdentityApi extends runtime.BaseAPI {

    /**
     * A passwordless login link which will be sent to a consumer and can be used to sign in to the consent dashboard 
     * Create a passwordless login link
     */
    async passwordlessLoginPostRaw(requestParameters: PasswordlessLoginPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("m2m", ["passwordless-login:write"]);
        }

        const response = await this.request({
            path: `/passwordless-login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordlessLoginLinkRequestToJSON(requestParameters.passwordlessLoginLinkRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * A passwordless login link which will be sent to a consumer and can be used to sign in to the consent dashboard 
     * Create a passwordless login link
     */
    async passwordlessLoginPost(requestParameters: PasswordlessLoginPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.passwordlessLoginPostRaw(requestParameters, initOverrides);
    }

    /**
     * A passwordless login link can have additional session metadata added to it. This schema defines that metadata e.g. firstName. 
     * Get the passwordless login session schema definition
     */
    async passwordlessLoginSchemasGetRaw(requestParameters: PasswordlessLoginSchemasGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SessionDataSchemaListResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page-size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("m2m", ["passwordless-login:write"]);
        }

        const response = await this.request({
            path: `/passwordless-login/schemas`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SessionDataSchemaListResponseFromJSON(jsonValue));
    }

    /**
     * A passwordless login link can have additional session metadata added to it. This schema defines that metadata e.g. firstName. 
     * Get the passwordless login session schema definition
     */
    async passwordlessLoginSchemasGet(requestParameters: PasswordlessLoginSchemasGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SessionDataSchemaListResponse> {
        const response = await this.passwordlessLoginSchemasGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a passwordless login link session metadata schema definition. 
     * Delete the passwordless login session schema definition
     */
    async passwordlessLoginSchemasSchemaIdDeleteRaw(requestParameters: PasswordlessLoginSchemasSchemaIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.schemaId === null || requestParameters.schemaId === undefined) {
            throw new runtime.RequiredError('schemaId','Required parameter requestParameters.schemaId was null or undefined when calling passwordlessLoginSchemasSchemaIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("m2m", ["passwordless-login:write"]);
        }

        const response = await this.request({
            path: `/passwordless-login/schemas/{schemaId}`.replace(`{${"schemaId"}}`, encodeURIComponent(String(requestParameters.schemaId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a passwordless login link session metadata schema definition. 
     * Delete the passwordless login session schema definition
     */
    async passwordlessLoginSchemasSchemaIdDelete(requestParameters: PasswordlessLoginSchemasSchemaIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.passwordlessLoginSchemasSchemaIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * A passwordless login link can have additional session metadata added to it. This schema defines that metadata e.g. firstName. 
     * Create or update the passwordless login session schema definition
     */
    async passwordlessLoginSchemasSchemaIdPutRaw(requestParameters: PasswordlessLoginSchemasSchemaIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.schemaId === null || requestParameters.schemaId === undefined) {
            throw new runtime.RequiredError('schemaId','Required parameter requestParameters.schemaId was null or undefined when calling passwordlessLoginSchemasSchemaIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("m2m", ["passwordless-login:write"]);
        }

        const response = await this.request({
            path: `/passwordless-login/schemas/{schemaId}`.replace(`{${"schemaId"}}`, encodeURIComponent(String(requestParameters.schemaId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SessionDataSchemaToJSON(requestParameters.sessionDataSchema),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * A passwordless login link can have additional session metadata added to it. This schema defines that metadata e.g. firstName. 
     * Create or update the passwordless login session schema definition
     */
    async passwordlessLoginSchemasSchemaIdPut(requestParameters: PasswordlessLoginSchemasSchemaIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.passwordlessLoginSchemasSchemaIdPutRaw(requestParameters, initOverrides);
    }

    /**
     * After a passwordless login link is sent to a consumer, the link is clicked and a new session is generated 
     * Create a session from a passwordless login link
     */
    async passwordlessSignInPostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/passwordless-sign-in`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * After a passwordless login link is sent to a consumer, the link is clicked and a new session is generated 
     * Create a session from a passwordless login link
     */
    async passwordlessSignInPost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.passwordlessSignInPostRaw(initOverrides);
    }

}
