import React, { ReactElement, Suspense, useEffect, useState } from 'react';
import MuiDrawer from '@mui/material/Drawer';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import { Box, Divider, IconButton, Tooltip } from '@mui/material';
import { LoadingSpinner } from '../../atoms/loading/loading-spinner.atom';
import { LeftArrowIcon, RightArrowIcon } from '../../atoms/icons/icons.atom';

let drawerWidth = 260;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(4)} + 1px)`,
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

interface Props {
  anchorSide?: 'left' | 'right';
  lazyload?: boolean;
  gutter?: boolean;
  open?: boolean;
  width?: number;
  children: ReactElement;
  onClose?: (isOpen: boolean) => void;
}

export const LayoutSideDrawer = ({
  children,
  anchorSide = 'left',
  lazyload = false,
  gutter = false,
  open = false,
  width = 260,
  onClose,
}: Props) => {
  drawerWidth = width;
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleDrawerToggle = () => {
    setIsOpen(!isOpen);
    if (onClose) {
      onClose(!isOpen);
    }
  };

  return (
    <Drawer variant="permanent" open={isOpen} anchor={anchorSide}>
      <Box>
        <Box sx={{ height: '6.4rem' }} />
        <Box sx={{ display: 'flex', justifyContent: anchorSide == 'left' ? 'end' : 'start', p: '4px' }}>
          <Tooltip title={isOpen ? 'Close' : 'Open'}>
            <IconButton aria-label="toggle drawer" onClick={handleDrawerToggle} edge="start" size="small">
              {anchorSide === 'left' && (
                <>
                  {isOpen && <LeftArrowIcon />}
                  {!isOpen && <RightArrowIcon />}
                </>
              )}
              {anchorSide === 'right' && (
                <>
                  {isOpen && <RightArrowIcon />}
                  {!isOpen && <LeftArrowIcon />}
                </>
              )}
            </IconButton>
          </Tooltip>
        </Box>
        <Divider />
        {!lazyload && <Box sx={{ p: gutter ? 1.6 : 0, ml: gutter ? 3.6 : 0 }}>{children}</Box>}
        {lazyload && (
          <Box sx={{ p: gutter ? 1.6 : 0, pr: gutter ? 3 : 0, ml: gutter ? 3.6 : 0 }}>
            <Suspense fallback={<LoadingSpinner />}>{isOpen && children}</Suspense>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};
