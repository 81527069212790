/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * sharingDuration for Once_off consent can only be "ONCE_OFF" <br/>
 * sharingDuration for Ongoing consent could be one of predefined sharing durations or "CUSTOM" <br/>
 * "CUSTOM" (Ongoing consent) means a consumer is free to choose a sharingEndDate
 * @export
 */
export const SharingDuration = {
    OneDay: 'ONE_DAY',
    OneWeek: 'ONE_WEEK',
    TwoWeeks: 'TWO_WEEKS',
    OneMonth: 'ONE_MONTH',
    ThreeMonths: 'THREE_MONTHS',
    SixMonths: 'SIX_MONTHS',
    NineMonths: 'NINE_MONTHS',
    OneYear: 'ONE_YEAR',
    SevenYears: 'SEVEN_YEARS',
    Custom: 'CUSTOM',
    OnceOff: 'ONCE_OFF'
} as const;
export type SharingDuration = typeof SharingDuration[keyof typeof SharingDuration];


export function SharingDurationFromJSON(json: any): SharingDuration {
    return SharingDurationFromJSONTyped(json, false);
}

export function SharingDurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SharingDuration {
    return json as SharingDuration;
}

export function SharingDurationToJSON(value?: SharingDuration | null): any {
    return value as any;
}

