/* tslint:disable */
/* eslint-disable */
/**
 * Adatree REST API for managing Software Products
 * DCR Management APIs allow ADR customers to * enable / disable software products after it is LIVE in ACCC registry and synced back into Adatree ADR platform * provides a way to soft launch software products or take software products offline 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HealthyDcrClient
 */
export interface HealthyDcrClient {
    /**
     * 
     * @type {string}
     * @memberof HealthyDcrClient
     */
    dataHolderName: string;
    /**
     * 
     * @type {string}
     * @memberof HealthyDcrClient
     */
    dcrUpdated: string;
}

/**
 * Check if a given object implements the HealthyDcrClient interface.
 */
export function instanceOfHealthyDcrClient(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dataHolderName" in value;
    isInstance = isInstance && "dcrUpdated" in value;

    return isInstance;
}

export function HealthyDcrClientFromJSON(json: any): HealthyDcrClient {
    return HealthyDcrClientFromJSONTyped(json, false);
}

export function HealthyDcrClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): HealthyDcrClient {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataHolderName': json['dataHolderName'],
        'dcrUpdated': json['dcrUpdated'],
    };
}

export function HealthyDcrClientToJSON(value?: HealthyDcrClient | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataHolderName': value.dataHolderName,
        'dcrUpdated': value.dcrUpdated,
    };
}

