/* tslint:disable */
/* eslint-disable */
/**
 * Adatree ADR Platform Machine Client API
 * A machine-to-machine consent REST API for ADRs. <p>This allows ADR customers to query for consents granted by their customers to access CDR consumer data </p> <p>It also provides a mechanism to configure scopes and use-cases which are then made available in the consent dashboard.</p>
 *
 * The version of the OpenAPI document: 1.4.0
 * Contact: engineering@adatree.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DataHolderAuthorizationResponse
 */
export interface DataHolderAuthorizationResponse {
    /**
     * response payload from the Dataholder after a successful consent
     * @type {string}
     * @memberof DataHolderAuthorizationResponse
     */
    response?: string;
}

/**
 * Check if a given object implements the DataHolderAuthorizationResponse interface.
 */
export function instanceOfDataHolderAuthorizationResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DataHolderAuthorizationResponseFromJSON(json: any): DataHolderAuthorizationResponse {
    return DataHolderAuthorizationResponseFromJSONTyped(json, false);
}

export function DataHolderAuthorizationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataHolderAuthorizationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'response': !exists(json, 'response') ? undefined : json['response'],
    };
}

export function DataHolderAuthorizationResponseToJSON(value?: DataHolderAuthorizationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'response': value.response,
    };
}

